/* Module: S */
.quote {
	padding: pxToRem(20) 0;

	&__item {
		padding: pxToRem(1) 0;
	}

	&__text {
		font-size: 2.5em; //40
		font-weight: 900;
		line-height: 1.2;
		color: $teal-active;
		@include font-smoothing;
	}

	&__author {
		font-size: 0.938em; //15
		font-weight: 900;
		line-height: 1.47;
		text-transform: uppercase;
		padding-top: pxToRem(20);
	}

	&__info {
		font-size: 0.938rem; //15
		line-height: 1.47;
		text-transform: uppercase;
		padding-top: pxToRem(5);
	}
}

.main {
	&.pink {
		.quote {
			&__text {
				color: $pink-active;
			}
		}
	}

	&.orange {
		.quote {
			&__text {
				color: $orange-active;
			}
		}
	}
	
	&.olive {
		.quote {
			&__text {
				color: $olive-active;
			}
		}
	}
	
	&.azure {
		.quote {
			&__text {
				color: $azure-active;
			}
		}
	}
	
	&.teal {
		.quote {
			&__text {
				color: $teal-active;
			}
		}
	}
	
	&.purple {
		.quote {
			&__text {
				color: $purple-active;
			}
		}
	}
}