/* Module: S */
.accordion {
	padding-bottom: pxToRem(30);

	&__item {
		padding-top: pxToRem(30);

		& + * {
			margin-top: pxToRem(30);
		}

		& + .accordion {
			&__item {
				padding-top: pxToRem(2);
				margin-top: 0;
			}
		}

		& + .responsive-table {
			margin-top: pxToRem(60);
		}
	}
	
	&__title {        
		button {
		    padding: pxToRem(24) pxToRem(63) pxToRem(24) pxToRem(24);

	        &:before,
	        &:after {
	            top: pxToRem(36);
	            right: pxToRem(31); 
	        }
		}
	}
}