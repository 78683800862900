/* Module: S */
.side-navigation {
    background-color: $charcoal-grey;
    display: none;
    @include font-smoothing;
    margin-top: pxToRem(10);
    margin-bottom: pxToRem(10);

	&__toggle {
	    position: absolute;
	    top: 0;
	    right: 0;
	    width: pxToRem(34);
	    height: pxToRem(48);
	    padding: pxToRem(14) pxToRem(10);
	    color: $sunflower-yellow;
	    background-color: transparent;
	    border: none;
	    border-radius: 0;
	    -webkit-appearance: none;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $sunflower-yellow;
            @include sq-transition((opacity .3s, transform .3s));   
        }

        &:before {
            width: pxToRem(2);
            height: pxToRem(12);
            margin-left: pxToRem(-1);
            margin-top: pxToRem(-6);
        }

        &:after {
            width: pxToRem(12);
            height: pxToRem(2);
            margin-left: pxToRem(-6);
            margin-top: pxToRem(-1);
        }

        &.active {
            &:before {
                @include sq-transform(rotate(90deg));
            }

            &:after {
                @include sq-transform(rotate(90deg));
                opacity: 0;
            }
        }
	}

    &__link {
        border-bottom: 1px solid $warm-grey;
        position: relative;

        &.parent {
            a {
                padding-right: pxToRem(44);
            }
        }

        &.current {
            a {
                background-color: $battleship-grey-two;                    
            }
        }

        a {
            display: block;
            font-size: 0.938em; //15
            text-decoration: none;
            color: $white;
            padding: pxToRem(12) pxToRem(20);
            @include sq-transition(background-color 0.3s);

            &:hover,
            &:focus {
                background-color: $battleship-grey-two;
            }
        }
    }

    &__list {
        @extend %clearfix;
        padding: 0;
        margin: 0;
        display: none;

        &.active {
        	display: block;
        }

        >li {
	        padding: 0;
	        margin: 0;
	        list-style-type: none;
        	border-bottom: 1px solid $warm-grey;

            &.current {
                a {
                    background-color: $battleship-grey-two;                    
                }
            }

        	a {
        		display: block;
        		font-size: 0.938em; //15
        		text-decoration: none;
        		color: $white;
        		padding: pxToRem(12) pxToRem(20) pxToRem(12) pxToRem(40);
        		@include sq-transition(background-color 0.3s);

        		&:hover,
        		&:focus {
        			background-color: $battleship-grey-two;
        		}
        	}
        }
    }

    ul {
        padding: 0;
        margin: 0;

        >li {
            padding: 0;
            margin: 0;
            list-style-type: none;

        }
    }
}