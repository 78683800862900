/* Module: S */
.event-list {
	margin: pxToRem(-15);
	padding: 0 pxToRem(34) pxToRem(32) pxToRem(34);

	&__item {
		padding: pxToRem(15);
		width: 33.33%;

		a {
			padding-bottom: 100%;
		}
		
		&-large {
			width: 66.66%;
		}

		&-single {
			width: 100%;
			padding: pxToRem(10) 0;
			
			a {
        		padding-bottom: pxToRem(190);
			}
		}
	}

	&-featured {
		.event-list {
			&__item {
			    &:nth-child(4) {
			    	clear: left;
			    }
			}
		}
	}
}