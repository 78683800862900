/* Module: S */
.ad-carousel {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@extend %clearfix;

	&__item {
		float: left;
		overflow: hidden;
	}

	a {
		display: block;
		text-decoration: none;
		background-size: cover;
	}
}