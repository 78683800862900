/* Module: S */
.filter {
	padding: pxToRem(30) 0;

	&__content {
		padding: pxToRem(24) pxToRem(30) pxToRem(10) pxToRem(30);
		position: relative;
	}

	&__column {
		width: auto;
		max-width: pxToRem(446);

		&-full {
			float: right;
		}

		.filter {
			&__controls {
				padding-top: pxToRem(224);
			}
		}
	}
	
	&__search {
		width: 33.33%;
	}

	&__select {
		width: 33.33%;
	}

	&__date {
		float: left;
		width: 33.33%;
	}

	&-whatson {
		padding-bottom: 0;
	}

	&__controls {
		padding-top: pxToRem(31);
		float: right;
		width: 33.33%;
		padding-bottom: pxToRem(24);
	}
}