/* Module: S */
.event-list {
	@extend %clearfix;
	padding: 0 pxToRem(10) pxToRem(20) pxToRem(10);

	&__item {
		padding: pxToRem(5) 0;
		@include font-smoothing;
		width: 100%;
		float: left;
		display: none;

	    &:nth-child(-n+18) {
	        display: block;
	    }	

		a {
			text-decoration: none;
			display: block;
	        position: relative;
	        width: 100%;
			height: 0;
	        padding-bottom: pxToRem(165);
	        overflow: hidden;

			&:hover,
			&:focus {
				.event-list {
					&__image {
						@include sq-transform(scale(1.1));	
					}

					&__details {
						opacity: 1;
					}
				}
			}
		}

		&-ad {
			a {
    			padding-bottom: 100%;
			}
		}

		&-wrapper {
    		padding-bottom: 100%;
    		position: relative;
		}

		&-single {
			float: none;
			padding: pxToRem(10) 0;
		}

		&-large {
			a {
        		padding-bottom: 100%;
			}

			.event-list {
				&__date {
					font-size: 1.313em; //21
					border-left-width: pxToRem(36);
					padding: pxToRem(10) 0 pxToRem(9) 0;

					div {
						display: inline;
						padding: pxToRem(10) 0;
					}

					span {
						left: pxToRem(-18);
					}
				}

				&__title {
					font-size: 1.688em; //27
					border-left-width: pxToRem(36);
					padding: pxToRem(15) 0;

					div {
						display: inline;
						padding: pxToRem(15) 0;
					}

					span {
						left: pxToRem(-18);
					}
				}

				&__details {
					font-size: 1.688em; //27
				}
			}
		}

		&-more {
			width: 100%;
			text-align: center;
			float: left;
			padding-top: pxToRem(30);

			button {
				color: $battleship-grey;
				font-size: 1.125em; //18
				font-weight: 900;
	            border: none;
	            background-color: transparent;
	            text-transform: uppercase;
	            @include font-smoothing; 
				@include sq-transition(color 0.3s);

	            &:hover {
	            	color: $black-3;

	            	svg {
	            		path {
	            			fill: $black-3;
	            		}
	            	}
	            }

				svg {
					path {
						@include sq-transition(fill 0.3s);
					}
				}

				span {
					padding-right: pxToRem(10);
				}

			}
		}
	}

	&__image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-position: center;
		@include sq-transform(scale(1));
		@include sq-transition(all 0.3s);
	}

	&__info {
		position: absolute;
		left: 0;
		bottom: pxToRem(-1);
	}

	&__date {
		font-size: 0.875em; //14
		font-weight: bold;
		color: $white;
		text-transform: uppercase;
		border-left: pxToRem(28) solid $blueberry;
		padding: pxToRem(6) 0;
		margin-bottom: pxToRem(-1);

		div {
			display: inline;
			background-color: $blueberry;
			padding: pxToRem(6) 0;
		}

		span {
			position: relative;
			left: pxToRem(-14);
		}
	}

	&__title {
		font-size: 1em; //16
		font-weight: bold;
		color: $white;
		border-left: pxToRem(28) solid $charcoal-grey;
		padding: pxToRem(3.84) 0;

		div {
			display: inline;
			background-color: $charcoal-grey;
			padding: pxToRem(4) 0;
		}

		span {
			position: relative;
			left: pxToRem(-14);
		}
	}

	&__details {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba($charcoal-grey, 0.9);
		font-size: 1em; //16
		font-weight: bold;
		color: $white;
		padding: pxToRem(6) pxToRem(14);
		text-align: center;
		opacity: 0;
		@include sq-transition(opacity 0.3s);
	    @include sq-flex-box;
	    @include sq-justify-content(center);
	    @include sq-flex-direction(column);	
	}

	&-featured {
		.event-list {
			&__item {
			    &:nth-child(n+16) {
			        display: none;
			    }
			}
		}
	}
}