/* Module: S */
.page-navigation {
	@extend %clearfix;
	padding: pxToRem(20) 0;

	a {
		text-decoration: none;
		display: block;

		&:hover {
			.page-navigation {
				&__title {
					text-decoration: underline;
				}
			}
		}
	}

	&__item {
		width: 50%;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: pxToRem(3);
			width: pxToRem(8);
			height: pxToRem(12);
			display: block;
		}

		&-prev {
			float: left;
			text-align: left;
			padding-right: pxToRem(15);

			&:before {
				left: 0;
                background: url(../mysource_files/arrow-small-left.svg) top left no-repeat;
			}

			a {
				padding-left: pxToRem(15);

			}
		}

		&-next {
			float: right;
			text-align: right;
			padding-left: pxToRem(15);

			&:before {
				right: 0;
                background: url(../mysource_files/arrow-small-right.svg) top right no-repeat;
			}

			a {
				padding-right: pxToRem(15);
			}
		}
	}

	&__label {
		font-size: 0.875em; //14
		font-weight: 500;
		color: $black-3;
		text-transform: uppercase;
	}

	&__title {
		font-size: 18px;
		font-weight: 900;
		line-height: 1.44;
		color: $charcoal-grey;
		padding-top: pxToRem(5);
	}
}