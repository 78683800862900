/* Module: S */
body {
    @include sq-transition((none)); 
    left: auto;

    .header {
    	@include sq-transition((none)); 
    }

	&.navigation-open {
		left: auto;
		overflow: auto;

		.header {
			left: auto;
			-webkit-transform: translateZ(0);
		}
	}

	&.navigation-fixed {
		.header {
			-webkit-transform: translateZ(0);
		}
	}
}

.navigation {
    position: static;
    top: auto;
    left: auto;
    bottom: auto;
    background-color: transparent;
    z-index: 99;
    @include sq-transition((none)); 
    overflow: visible;
    visibility: visible;
    width: 100%;

    &.active {
        left: auto;
        visibility: visible;
    }

    &.shifted {
        .navigation {
        	&__wrapper {
        		left: auto;
        	}
        }
    }

    &__wrapper {
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        min-height: 0;
        @include sq-transition((none)); 
	}

	&__button {
	    display: none;
	}

	&__back {
	    display: none;
	}

	&__close {
	    display: none;
	}

    &__toggle {
        display: none;
    }

    &__title {
        display: block;
        width: 20%;
        float: left; 
        padding-top: pxToRem(7);
        padding-bottom: pxToRem(8);
        border-bottom: 1px solid $warm-grey;

    	a {
	        display: inline-block;
	        font-size: pxToRem(24);
	        font-weight: 900;
	        color: $pinkish-grey;
	        text-decoration: none;
	        @include sq-transition(color 0.3s);

	        &:hover,
	        &:focus {
	        	color: $white;
	        }
    	}
    }

    &__info {
        width: 20%;
        float: left; 
        display: block;     

        .navigation {
            &__title {
                width: auto;
                float: none;
            }
        }
    }

    &__description {
        padding-top: pxToRem(20);
        display: block;
        font-size: pxToRem(14);
        font-weight: 500;
        color: $pinkish-grey;

    }

    &__level-1 {
        padding: 0;
        width: 100%;
        margin:  0 pxToRem(-28);

        >li {
        	float: left;

    		&:hover,
    		&.open {
				.navigation__level-2-wrapper {
					display: block;
				}
    		}
        }

        &-link {
        	border-bottom: none;
        	position: static;

        	&.parent {
        		a {
        			padding-right: pxToRem(28);
        		}
        	}

        	a {
        		display: block;
        		font-size: 0.875em;
        		color: $black;
        		padding: pxToRem(18) pxToRem(28);
        		text-transform: uppercase;
        		@include font-smoothing-reset;
        		position: relative;

        		&.highlighted,
        		&.open {
        			&:after {
        				opacity: 1;
        			}
        		}

        		&:after {
        			content: '';
        			position: absolute;
        			bottom: 0;
        			left: pxToRem(30);
        			right: pxToRem(30);
        			height: pxToRem(3);
        			background-color: $sunflower-yellow;
        			opacity: 0;
        			@include sq-transition(opacity 0.3s);
        		}

        		&:hover,
        		&:focus {
        			background-color: transparent;

        			&:after {
        				opacity: 1;
        			}
        		}
        	}
        }
    }

    &__level-2 {
        width: 40%;
        float: left;
        padding-left: pxToRem(24);
        position: relative;

        &-wrapper {
		    top: pxToRem(55);
		    left: 0;
		    right: 0;
		    width: auto;
		    bottom: auto;
		    padding: pxToRem(30) pxToRem(40) pxToRem(40) pxToRem(40);
		    background-color: $dark;
		    visibility: visible;
		    display: none;

		    &.open {
		    	display: block;
		    }
        }

        &-link {
            &.parent {
                position: relative;

                &:after {
                    content: '';
                    width: pxToRem(34);
                    height: pxToRem(48);
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-image: url(../mysource_files/arrow-right-yellow.svg);
                    background-size: pxToRem(20);
                    background-repeat: no-repeat;
                    background-position: center;
                    z-index: -1;
                }
            }

            a {
                &:hover,
                &:focus {
                    background-color: transparent;
                }
            }
        }



        >li {
            &:hover,
            &.open {
                .navigation {
                    &__level-2-link {
                        border-bottom: 1px solid $white;

                        a {
                            color: $sunflower-yellow;
                        }
                    }

                    &__level-3 {
                        display: block;
                    }
                }
            }
        }
    }

    &__level-3 {
        display: none;
        position: absolute;
        top: 0;
        //bottom: 0;
        left: 100%;
        width: 100%;
        padding-left: pxToRem(24);

        >li {
        	border-bottom: none;

        	a {
        		padding: pxToRem(4) 0;
        		display: block;
                border-bottom: 1px solid $warm-grey;
                font-size: pxToRem(14);
                padding: pxToRem(15) pxToRem(10) pxToRem(14) pxToRem(10);

        		&:hover,
        		&:focus {
        			background-color: transparent;
        			text-decoration: none;
                    border-bottom: 1px solid $white;
                    color: $sunflower-yellow;
        		}
        	}

        }
    }

}