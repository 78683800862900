/* Module: S */
.map {
	padding: pxToRem(20) 0;

	&__wrapper {
		position: relative;
		padding-bottom: 75%; /* 4:3 */
		height: 0;
	}

	iframe,
	.gMap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
