/* Module: S */
.pullout {
	position: fixed;
	top: pxToRem(112);
	right: pxToRem(-252);
	z-index: 98;
	@include sq-transition(right 0.3s);

	&.active {
		right: 0;

		.pullout {
			&__toggle {
				button {
					&:before {
						@include sq-transform(rotate(0deg));
					}

					&:after {
						content: 'Close';
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						padding: pxToRem(8) pxToRem(14) pxToRem(8) pxToRem(45);
						text-align: center;
						display: block;
					}	

					span {
						opacity: 0;
					}			
				}
			}

			&__content {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__toggle {
		@include sq-transform(rotate(-90deg));
		position: absolute;
		top: pxToRem(60);
		left: pxToRem(-105);
		width: pxToRem(166);

		button {
			background-color: $library-red;
			font-size: pxToRem(20);
			font-weight: bold;
			color: $white;
			padding: pxToRem(8) pxToRem(14) pxToRem(8) pxToRem(45);
			@include font-smoothing;
			border: none;
			outline: none;

			&:hover,
			&:focus {
				background-color: $library-active;
			}

		    &:before {
		        content: url(../mysource_files/arrow-down.svg);
		        width: pxToRem(21);
		        height: pxToRem(12);
		        line-height: 12px;
		        display: block;
		        position: absolute;
		        top: 50%;
		        margin-top: pxToRem(-6);
		        left: pxToRem(14);
				@include sq-transform(rotate(180deg));
			}
		}
	}

	&__content {
		background-color: $library-light;
		padding: pxToRem(24) pxToRem(20);
		width: pxToRem(252);
		box-shadow: 0 0 pxToRem(10) pxToRem(3) rgba(0,0,0,0.42);
		opacity: 0;
		visibility: hidden;
		@include sq-transition(opacity 0.3s, visibility 0.3s);
	}

	&__text {
		color: $charcoal-grey-two;

		a {
			color: $charcoal-grey-two;
		}

		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
		}

		li {
			padding: 0;
			margin: 0;

			a {
				text-decoration: none;
				border-bottom: 1px solid $charcoal-grey-two;
				padding: pxToRem(12) 0 pxToRem(12) pxToRem(40);
				display: block;
				font-size: pxToRem(16);
				line-height: 1.2;
				position: relative;

				svg {
					position: absolute;
					top: 50%;
					width: pxToRem(19);
					height: pxToRem(18);
					margin-top: pxToRem(-9);
					left: 0;
				}

				&:hover,
				&:focus {
					border-color: $library-red;

					svg {
						path {
							fill: $library-red;
						}
					}
				}
			}
		}
	}

	&__title {
		font-size: pxToRem(20);
		font-weight: bold;
		color: $library-red;
		padding-bottom: pxToRem(5);
	}
}