/* Module: S */
.page-wrapper {
	padding-top: pxToRem(92);
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	color: $white;
	@include font-smoothing;
	z-index: 99;
	background-color: $white;
	-webkit-transform: translateZ(0);

	&__inner {
		background-color: $charcoal-grey-two;
	}

	&__wrapper {
		@extend %clearfix;
		position: relative;
		height: pxToRem(92);
		padding: pxToRem(25) pxToRem(10) pxToRem(20) pxToRem(10);
	}


    &__accessibility {
        position: absolute;
        top: pxToRem(-25);
        left: pxToRem(10);
        z-index: 1;

        div {
            text-transform: uppercase;
            font-size: 0.875em; //14
            font-weight: 900;

            a {
                color: $charcoal-grey-two;
                opacity: 0;
                @include sq-transition((opacity .3s, visibility .3s)); 
                text-decoration: none;
                position: absolute;
                top: pxToRem(-40);
                min-width: pxToRem(160);

                &:focus {
                    opacity: 1;
                	top: 0;
                }
            }
        }
    }

	&__toggle {
		float: left;
		padding-right: pxToRem(10);

		button {
			padding: 0;
			margin: 0;
			background-color: transparent;
			border: none;
			-webkit-appearance: none;
			@include sq-transition(opacity 0.3s);

			&:hover,
			&:focus {
				opacity: 0.8;
			}
		}
	}

	&__logo {
		float: left;

		a {
			display: block;
		}

		img,
		svg {
			display: block;
			width: pxToRem(100);
		}
	}

	&__tools {
		float: right;
	}

	&__social {
		float: left;
		padding-right: pxToRem(8);

		ul {
			padding: 0;
			margin: 0;
			list-style-type: none;
			@extend %clearfix;

			li {
				float: left;
				padding: 0;
				margin: 0;

				a {
					overflow: hidden;
					display: block;
					@include sq-transition(opacity 0.3s);

					&:hover,
					&:focus {
						opacity: 0.8;
					}

					img {
						width: pxToRem(40);
						height: pxToRem(40);
					}
				}
			}
		}
	}

	&__search {
		position: absolute;
		top: pxToRem(92);
		left: 0;
		right: 0;
		background-color: $dark;
		padding: pxToRem(15) pxToRem(10);
		height: pxToRem(75);
		@include placeholder-color($charcoal-grey-two);
		display: none;

		&.active {
			display: block;
		}

		form {
			height: pxToRem(45);
			position: absolute;
			top: pxToRem(15);
			left: pxToRem(10);
			right: pxToRem(10);
		}

		input[type="text"] {
            font-size: 0.875em; //14
			color: $charcoal-grey-two;
			border: none;
			padding: pxToRem(13) pxToRem(60) pxToRem(13) pxToRem(15);
			width: 100%;
			@include clear-default-appearance;
			border-radius: 0;
		}

		input[type="submit"] {
			border: none;
			border-radius: 0;
			background-color: $light-grey;
			background-image: url(../mysource_files/search.svg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: pxToRem(23);
			text-indent: -99999px;
			padding: pxToRem(13) pxToRem(15);
			@include clear-default-appearance;
			position: absolute;
			top: 0;
			right: 0;
			padding: 0;
			width: pxToRem(45);
			height: pxToRem(45);
			@include sq-transition(background-color 0.3s);

			&:hover,
			&:focus {
				background-color: rgba($charcoal-grey-two, 0.15);
			}
		}

		&-toggle {
			float: left;

			button {
				@include clear-default-appearance;
				border: none;
				border-radius: 0;
				background-color: $light-grey;
				padding: 0;
				width: pxToRem(40);
				height: pxToRem(40);
				position: relative;
				@include sq-transition(background-color 0.3s);

				.close {
					opacity: 0;
					visibility: hidden;
					position: absolute;
					top: pxToRem(12);
					left: pxToRem(12);
					@include sq-transition(opacity 0.3s, visibility 0.3s);
				}

				.search {
					opacity: 1;
					visibility: visible;
					position: absolute;
					top: pxToRem(10);
					left: pxToRem(10);
					@include sq-transition(opacity 0.3s, visibility 0.3s);
				}

				&.active {
					.close {
						opacity: 1;
						visibility: visible;
					}

					.search {
						opacity: 0;
						visibility: hidden;
					}
				}

				&:hover,
				&:focus {
					background-color: rgba($light-grey, 0.8);
				}
			}
		}
	}

	&-simple {
		position: absolute;
	}
}