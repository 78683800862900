/**
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * updated: {{date}}
 *
 * Table of Contents
 *
 * - Reset
 * - Utilities
 * - General
 * - Content
 * - Modules
    {{toc}}
 */

// These imports have no CSS output, but provide variables, mixins and helpers to aid in construction of
// a design cutup.
@import "boilerplate.scss";

/*
--------------------
Reset (normalize.css)
--------------------
*/

@import "normalize.scss";

// This import disabled by default due to rendering issues, particularly with IE where some text doesn't render at all
//@import "normalize-opentype.scss";

// Box size all the things
// https://squizmap.squiz.net/imp-bp/6400
// http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// == Start box-sizing reset ==
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
html {
  @include sq-border-box;
}
// == End box-sizing reset ==

/*
--------------------
Utilities
--------------------
*/

@import "utilities.scss";

/*
--------------------
General
--------------------
*/

// Taken from HTML 5 BP
::-moz-selection {
  background: $content-selection-highlight;
  text-shadow: none;
}

::selection {
  background: $content-selection-highlight;
  text-shadow: none;
}

html,
body {
  height: 100%;
}

html {
  &.noscroll {
    overflow-y: hidden;

    body {
      overflow-y: hidden;
    }
  }
}

body {
  overflow-y: scroll;
  font-family: $base-font-family;
  font-size: $base-font-scale + em;
  color: $base-font-colour;
  line-height: 1.4;
  padding: 0;
  margin: 0;
  min-width: pxToRem(320);
}

a {
  color: $base-font-colour;
}

img {
  width: 100%;
  display: block;
}

// Reset fonts for relevant elements (taken from bootstrap)
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport {
  width: device-width;
}

// Print style that shouldn't belong to print.scss to avoid the css beautifier from killing
// the @ syntax inside media queries
@page {
  margin: 0.5cm;
}

/*
--------------------
Content
--------------------
*/

.page-wrapper {
  position: relative;
  overflow: hidden;
}

.main {
  position: relative;
  padding-bottom: pxToRem(35);
}

.section {
  padding: pxToRem(20) 0;

  &-full {
    padding: 0;
  }

  &__wrapper {
    padding: 0 pxToRem(10);
    max-width: pxToRem(1160); //1140 + 20
    margin: 0 auto;
    position: relative;
  }
}

.details {
  font-size: 0.875em; //14
  font-weight: 900;
  color: $battleship-grey;
  padding: pxToRem(15) 0;
  @include font-smoothing;
}

.back {
  padding: pxToRem(20) 0;

  a {
    display: inline-block;
    background-color: $blueberry;
    color: $white;
    font-size: 1.125em; //18
    font-weight: 900;
    @include font-smoothing;
    padding: pxToRem(10) pxToRem(30) pxToRem(10) pxToRem(50);
    text-decoration: none;
    position: relative;

    &:hover,
    &:focus {
      background-color: rgba($blueberry, 0.9);
    }

    &:before {
      content: "";
      position: absolute;
      top: pxToRem(11);
      left: pxToRem(18);
      width: pxToRem(14);
      height: pxToRem(22);
      background: url(../mysource_files/arrow-left.svg) left top no-repeat;
      background-size: pxToRem(12);
    }
  }
}

.text {
  p {
    font-size: 1.125em; //18
    font-weight: 300;
    line-height: 1.44;
    color: $black-2;
    margin: 0;
    padding: pxToRem(10) 0;

    a {
      color: $black-2;
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    img {
      width: auto;
      display: inline;
      max-width: 100%;
      height: auto;
    }
  }

  ul,
  ol {
    margin: 0;
    padding-top: pxToRem(20);
    padding-bottom: pxToRem(20);

    > li {
      font-size: 1.125rem; //18
      font-weight: 200;
      line-height: 2;
      padding-left: pxToRem(5);

      ol {
        list-style-type: lower-alpha;
        padding-top: 0;
        padding-bottom: 0;
      }

      ul {
        list-style-type: disc;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    a {
      color: $black-2;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

figcaption {
  padding: pxToRem(10);
  font-size: pxToRem(14);
}

h1,
.h1 {
  font-size: 2.188em; //35
  font-weight: 900;
  line-height: 1;
  color: $charcoal-grey;
  margin: 0;
  padding: pxToRem(15) 0;

  & + .details {
    margin-top: pxToRem(-5);
    padding-top: 0;
  }
}

h2,
.h2 {
  font-size: 1.563em; //25
  font-weight: 900;
  line-height: 1;
  color: $charcoal-grey;
  margin: 0;
  padding: pxToRem(10) 0;
}

h3,
.h3 {
  font-size: 1.375em; //22
  font-weight: 900;
  line-height: 1;
  color: $charcoal-grey;
  margin: 0;
  padding: pxToRem(5) 0;
}

h4,
.h4 {
  font-size: 1.125em; //18
  font-weight: 900;
  line-height: 1.17;
  color: $charcoal-grey;
  margin: 0;
  padding: pxToRem(5) 0;
}

h5,
.h5 {
  font-size: 0.938em; //15
  font-weight: 900;
  line-height: 1.4;
  color: $charcoal-grey;
  margin: 0;
  padding: pxToRem(5) 0;
}

.row {
  @extend %clearfix;
  position: relative;

  .column {
    &-section {
      padding-bottom: pxToRem(16);

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.wide-only {
  display: none;
}

/*
--------------------
Modules
--------------------
*/
