/* Module: S */
.gallery {
	padding: pxToRem(20) 0;
	@extend %clearfix;

	img {
		display: block;
	}

	&__item {
		float: left;
	}

	&__main {
		position: relative;

		.slick {
			&-arrow {
				top: 50%;
				bottom: auto;
				margin-top: pxToRem(-25);
				padding: 0;
				z-index: 9;
				background-color: transparent;
				background-repeat: no-repeat;
				width: pxToRem(35);
				height: pxToRem(50);
				text-indent: -9999px;
				border: none;
				opacity: 1;
				@include sq-transition(opacity 0.3s);

				&:hover {
					opacity: 0.6;
				}
			}

			&-prev {
				left: 0;
				right: auto;
				background-image: url(../mysource_files/arrow-left.svg);
				background-position: pxToRem(10);
			}

			&-next {
				right: 0;
				left: auto;
				background-image: url(../mysource_files/arrow-right.svg);
				background-position: pxToRem(10);
			}
		}
	}

	&__nav {
		display: none;
		position: relative;
		max-width: pxToRem(640);
		margin: pxToRem(20) auto 0 auto;
		padding: 0 pxToRem(40);

		.slick {
			&-slide {
				opacity: 0.57;
				@include sq-transition(opacity 0.3s);
				padding: 0 pxToRem(4);
			}

			&-current {
				opacity: 1;
			}

			&-list {
				margin: 0 pxToRem(-4);
				max-width: pxToRem(468);
				margin: 0 auto;
			}

			&-arrow {
				top: 50%;
				bottom: auto;
				margin-top: pxToRem(-25);
				padding: 0;
				z-index: 9;
				background-color: transparent;
				background-repeat: no-repeat;
				width: pxToRem(35);
				height: pxToRem(50);
				text-indent: -9999px;
				border: none;
				opacity: 1;
				@include sq-transition(opacity 0.3s);

				&:hover {
					opacity: 0.6;
				}
			}

			&-prev {
				left: 0;
				right: auto;
				background-image: url(../mysource_files/arrow-left-dark.svg);
				background-position: pxToRem(10);
			}

			&-next {
				right: 0;
				left: auto;
				background-image: url(../mysource_files/arrow-right-dark.svg);
				background-position: pxToRem(10);
			}
		}
	}
}