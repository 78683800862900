/* Module: S */
.alert {
    &__wrapper {
        padding: 0 pxToRem(34);
        max-width: pxToRem(1208); //1140 + 68       
    }

    &__close {
        top: pxToRem(25);
        right: pxToRem(24);
    }

    &__text {
        padding: pxToRem(30) pxToRem(40) pxToRem(30) pxToRem(80);
        background-position: 0 pxToRem(35);
    }

    p {
        font-size: 1.125em; //18
        line-height: 1.44;
    }
}