/* Module: S */
.alert {
    display: none;
    background-color: $sunflower-yellow-two;

    &__wrapper {
        padding: 0 pxToRem(10);
        max-width: pxToRem(1160); //1140 + 20
        margin: 0 auto;  
        position: relative;   
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
    }

    &__text {
        padding: pxToRem(40) 0 pxToRem(25) pxToRem(50);
        background-image: url('../mysource_files/alert.svg');
        background-position: 0 pxToRem(40);
        background-repeat: no-repeat;
    }

    button {
        width: pxToRem(37);
        height: pxToRem(37);
        padding: pxToRem(10);
        background-color: transparent;
        border: none;
        border-radius: 0;
        -webkit-appearance: none;
        text-align: right;
    }

    p {
        color: $charcoal-grey;
        line-height: 1.31;
        font-weight: bold;
        @include font-smoothing-reset;
        margin: 0;
    }

    a {
        color: $charcoal-grey;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}