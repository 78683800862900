/* Module: S */
.responsive-table {
	width: 100%;
	border-collapse: collapse;
	border-top: 1px solid $pinkish-grey-two;
	margin: pxToRem(20) 0;
	background-color: $white;

    th {
		font-size: 1.125em; //18
		font-weight: 900;
		line-height: 1.44;
		padding: pxToRem(20);
		@include font-smoothing;
		background-color: $teal;
		text-align: left;

        h1,
        h2,
        h3,
        h4,
        h5 {
            padding: 0;
        }
    }

    tbody {
    	margin-top: -1px;

	    tr {
	    	border-bottom: 1px solid $charcoal-grey-two;

            &:nth-child(even) {
                background-color: $white-two;
            }
	    }

        th {
            vertical-align: top;
        }
    }
    
    td {
        position: relative;
        font-size: pxToRem(18); //18
        font-weight: 300;
        line-height: 1.44;
        color: $black-2;
        vertical-align: top;
        padding: 0;
        border-right: 1px solid $pinkish-grey-two;

        &:first-child {
            border-left: 1px solid $pinkish-grey-two;
        }

        span {
        	padding: pxToRem(15);
            display: block;
        }

        p {
            font-size: pxToRem(18);
            font-weight: 300;
            line-height: 1.44;

            &:first-child {
                margin-top: 0;
                padding-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    &__thumbnail {
        padding-top: pxToRem(15);

        &:first-child {
            padding-top: 0;
        }

        a {
            display: block;
        }

        img {
            width: auto;
            max-width: 100%;
            max-height: pxToRem(300);
        }
    }

    &-plain {
        td {
            &:nth-child(even) {
                background-color: transparent;
            }
        }
    }
}

.js-responsive-table {
    tbody {
        display: block;
        margin-top: -1px;

        tr {
            display: block;
            border-bottom: 1px solid $charcoal-grey-two;
            @extend %clearfix;

            &:nth-child(even) {
                background-color: transparent;
            }
        }
    }

    th {
        position: absolute;
        top: 0;
        left: -999em;
    }

    td {
        display: block;
        width: 100%;
        float: left;
        border-bottom: 1px solid $pinkish-grey-two;

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-bottom: none;

            &:after {
                bottom: 0;
                border-bottom: none;
            }
        }

        &:before {
            content: attr(data-th);
            display: block;
            width: 50%;
            float: left;
            font-size: 1.125em; //18
            font-weight: 900;
            line-height: 1.44;
            padding: pxToRem(15);
            @include font-smoothing;
            position: relative;
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: -1px;
            left: 0;
            width: 50%;
            background-color: $teal;
            border-bottom: 1px solid $white;
        }

        &:nth-child(even) {
            background-color: $white-two;
        }

        span {
            width: 50%;
            float: left;
        }
    }
}

.main {
    &.pink {
        .responsive-table {
            th {
                background-color: $pink;
            }

            td {
                &:after {
                    background-color: $pink;
                }
            }
        }
    }

    &.orange {
        .responsive-table {
            th {
                background-color: $orange;
            }

            td {
                &:after {
                    background-color: $orange;
                }
            }
        }
    }
    
    &.olive {
        .responsive-table {
            th {
                background-color: $olive;
            }

            td {
                &:after {
                    background-color: $olive;
                }
            }
        }
    }
    
    &.azure {
        .responsive-table {
            th {
                background-color: $azure;
            }

            td {
                &:after {
                    background-color: $azure;
                }
            }
        }
    }
    
    &.teal {
        .responsive-table {
            th {
                background-color: $teal;
            }

            td {
                &:after {
                    background-color: $teal;
                }
            }
        }
    }
    
    &.purple {
        .responsive-table {
            th {
                background-color: $purple;
            }

            td {
                &:after {
                    background-color: $purple;
                }
            }
        }
    }
}