//Colors
$white: #ffffff;
$white-2: #f4f3f0;
$white-two: #f3f3f3;
$black: #000000;
$black-2: #090909;
$black-3: #393939;
$charcoal-grey: #343e48;
$charcoal-grey-two: #2d3a41;
$blueberry: #5c348b;
$blueberry-light: #6c4896;
$battleship-grey: #767981;
$battleship-grey-two: #727980;
$pinkish-grey: #d8d2c4;
$pinkish-grey-two: #cbcbcb;
$warm-grey: #979797;
$dark: #1d272c;
$light-grey: #ebe9e2;
$sunflower-yellow: #fed700;
$sunflower-yellow-two: #fbd705;
$silver: #e4e4e6;
$cherry-red: #e60035;

//Pink
$pink: #f6c1da;
$pink-active: #eb5ca0;

//Orange
$orange: #f9c8b9;
$orange-active: #f07766;

//Olive
$olive: #e5e5c4;
$olive-active: #bbbc62;

//Azure
$azure: #ade0f9;
$azure-active: #21baf0;

//Teal
$teal: #cbe9eb;
$teal-active: #73cbd3;

//Purple
$purple: #beb6d8;
$purple-active: #7d69b2;

//Khaki
$khaki-active: #7c7257;

$library-red: #c8102e;
$library-active: #d73b3f;
$library-light: #fcf2ef;

// Base font
$base-font-scale: 1; // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: $black-2;
$base-font-family: "Nunito Sans", Arial, Helvetica, sans-serif;

// Selection highlighting
$content-selection-highlight: #b3d4fc;
