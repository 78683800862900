/* Module: S */
body {
    @include sq-transition((left .3s)); 
    left: 0;

    .header {
    	@include sq-transition((left .3s)); 
    }

	&.navigation-open {
		left: pxToRem(260);
		overflow: hidden;

		.header {
			left: pxToRem(260);
			-webkit-transform: none;
		}
	}

	&.navigation-fixed {
		.header {
			-webkit-transform: none;
		}
	}
}

.navigation {
    position: fixed;
    top: 0;
    left: pxToRem(-260);
    width: pxToRem(260);
    bottom: 0;
    background-color: $charcoal-grey;
    z-index: 99;
    @include sq-transition((left .3s, visibility .3s)); 
    overflow-x: hidden;
    overflow-y: auto;
    visibility: hidden;

    &.active {
        left: 0;
        visibility: visible;
    }

    &.shifted {
        .navigation {
        	&__wrapper {
        		left: -100%;
        	}
        }
    }

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        min-height: 100%;
        @include sq-transition((left .3s)); 
	}

	&__button {
	    position: absolute;
	    top: 0;
	    right: 0;
	    width: pxToRem(40);
	    height: pxToRem(48);
	    padding: pxToRem(14) pxToRem(10);
	    color: $sunflower-yellow;
	    background-color: transparent;
	    border: none;
	    border-radius: 0;
	    -webkit-appearance: none;
	    
	  	svg {
	  		width: pxToRem(20);

	  		path {
	  			fill: $sunflower-yellow;
	  		}
	  	}
	}

	&__toggle {
	    position: absolute;
	    top: 0;
	    right: 0;
	    width: pxToRem(34);
	    height: pxToRem(48);
	    padding: pxToRem(14) pxToRem(10);
	    color: $sunflower-yellow;
	    background-color: transparent;
	    border: none;
	    border-radius: 0;
	    -webkit-appearance: none;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $sunflower-yellow;
            @include sq-transition((opacity .3s, transform .3s));   
        }

        &:before {
            width: pxToRem(2);
            height: pxToRem(12);
            margin-left: pxToRem(-1);
            margin-top: pxToRem(-6);
        }

        &:after {
            width: pxToRem(12);
            height: pxToRem(2);
            margin-left: pxToRem(-6);
            margin-top: pxToRem(-1);
        }

        &.active {
            &:before {
                @include sq-transform(rotate(90deg));
            }

            &:after {
                @include sq-transform(rotate(90deg));
                opacity: 0;
            }
        }
	}

	&__back {
	    width: 100%;
	    padding: pxToRem(13) pxToRem(10) pxToRem(13) pxToRem(40);
	    color: $sunflower-yellow;
	    background-color: transparent;
	    border: none;
	    border-radius: 0;
	    -webkit-appearance: none;
    	border-bottom: 1px solid $warm-grey;
    	text-align: left;

		&:hover,
		&:focus {
			background-color: $battleship-grey-two;
		}
	    
	  	svg {
	  		position: absolute;
	  		top: pxToRem(14);
	  		left: pxToRem(10);
	  		width: pxToRem(20);
	  		@include sq-transform(rotate(180deg));

	  		path {
	  			fill: $sunflower-yellow;
	  		}
	  	}
	}

	&__close {
	    width: 100%;
	    padding: pxToRem(19) pxToRem(10) pxToRem(19) pxToRem(40);
	    color: $white;
	    background-color: transparent;
	    border: none;
	    border-radius: 0;
	    -webkit-appearance: none;
    	border-bottom: 1px solid $warm-grey;
    	text-align: left;
    	font-size: 0.625em; //10
    	line-height: 2.5;
    	text-transform: uppercase;
		font-weight: 900;
		letter-spacing: 1.4px;
        width: pxToRem(260);
        position: absolute;
        left: 0;

		&:hover,
		&:focus {
			background-color: $battleship-grey-two;
		}

		&.fixed {
			position: fixed;
		}
	    
	  	svg {
	  		position: absolute;
	  		top: pxToRem(19);
	  		left: pxToRem(10);
	  	}
	}

    &__title,
    &__info {
        display: none;
    }

    &__level-1 {
        @extend %clearfix;
        padding: pxToRem(64) 0 0 0;
        margin: 0;
        width: 50%;

        >li {
	        padding: 0;
	        margin: 0;
	        list-style-type: none;

        }

        &-link {
        	border-bottom: 1px solid $warm-grey;
        	position: relative;

        	&.parent {
        		a {
        			padding-right: pxToRem(40);
        		}
        	}

        	a {
        		display: block;
        		font-size: 1em; //16
        		text-decoration: none;
        		color: $white;
        		padding: pxToRem(13) pxToRem(10);
        		@include sq-transition(background-color 0.3s);

        		&:hover,
        		&:focus {
        			background-color: $battleship-grey-two;
        		}
        	}
        }
    }

    &__level-2 {
        @extend %clearfix;
        padding: 0;
        margin: 0;

        &-wrapper {
		    position: absolute;
		    top: pxToRem(64);
		    left: 50%;
		    width: 50%;
		    bottom: 0;
		    z-index: 99;
		    background-color: $charcoal-grey;
		    visibility: hidden;
            @extend %clearfix;

		    &.active {
		        visibility: visible;
		    }

        }

        >li {
	        padding: 0;
	        margin: 0;
	        list-style-type: none;

        }

        &-link {
        	border-bottom: 1px solid $warm-grey;
        	position: relative;

            &.parent {
                a {
                    padding-right: pxToRem(34);
                }
            }

        	a {
        		display: block;
        		font-size: 1em; //16
        		text-decoration: none;
        		color: $white;
        		padding: pxToRem(13) pxToRem(10);
        		@include sq-transition(background-color 0.3s);

        		&:hover,
        		&:focus {
        			background-color: $battleship-grey-two;
        		}
        	}
        }
    }

    &__level-3 {
        @extend %clearfix;
        padding: 0;
        margin: 0;
        display: none;

        &.active {
        	display: block;
        }

        >li {
	        padding: 0;
	        margin: 0;
	        list-style-type: none;
        	border-bottom: 1px solid $warm-grey;

        	a {
        		display: block;
        		font-size: 1em; //16
        		text-decoration: none;
        		color: $white;
        		padding: pxToRem(13) pxToRem(10) pxToRem(14) pxToRem(25);
        		@include sq-transition(background-color 0.3s);

        		&:hover,
        		&:focus {
        			background-color: $battleship-grey-two;
        		}
        	}

        }
    }

}