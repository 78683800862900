/* Module: S */
.alert {
    &__wrapper {
        padding: 0 pxToRem(40);
        max-width: pxToRem(1220); //1140 + 80     
    }

    &__close {
        top: 50%;
        margin-top: pxToRem(-18);
        right: pxToRem(30);
    }
}