/* Module: S */
.filter {
	padding: pxToRem(30) 0;

	&__content {
		padding: pxToRem(24) pxToRem(30) pxToRem(34) pxToRem(30);
	}

	&__row {
		margin: 0 pxToRem(-25);
	}

	&__column {
		width: pxToRem(446);
		float: left;
		padding: 0 pxToRem(25);

		&-full {
			width: 100%;
		}

		.filter {
			&__select,
			&__search,
			&__daterange,
			&__date,
			&__inlinedate,
			&__controls {
				width: 100%;
			}
		}
	}

	&__select,
	&__search,
	&__daterange,
	&__inlinedate {
		float: left;
		width: 50%;
	}

	&__date {
		float: right;
		width: 50%;
		padding-left: pxToRem(30);
	}

	&__controls {
		float: right;
		width: 100%;
	}

	&-whatson {
		padding-bottom: 0;
	}

	&-search {
		.filter {
			&__content {
				padding: 0;
			}
			
			&__search {
				width: 100%;
			}
		}
	}
}