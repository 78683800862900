/* Module: S */
.accordion {
	padding-bottom: pxToRem(20);

	&__item {
		padding-top: pxToRem(20);

		& + * {
			margin-top: pxToRem(20);
		}

		& + .accordion {
			&__item {
				padding-top: pxToRem(2);
				margin-top: 0;
			}
		}

		& + .responsive-table {
			margin-top: pxToRem(40);
		}
	}

	&__title {
	    position: relative;

		button {
		    width: 100%;
		    padding: pxToRem(20) pxToRem(45) pxToRem(20) pxToRem(15);
		    color: $charcoal-grey-two;
		    background-color: $teal;
		    border: none;
		    border-radius: 0;
		    -webkit-appearance: none;
		    text-align: left;
		    font-size: 1.125em; //18
		    font-weight: 900;
			line-height: 1.44;
		    @include sq-transition((background-color .3s, color .3s));   
		    @include font-smoothing;

		    &:hover,
		    &:focus {
		    	background-color: $teal-active;	
		    }
	        
	        &:before,
	        &:after {
	            content: '';
	            position: absolute;
	            top: pxToRem(32);
	            right: pxToRem(22);
	            background-color: $charcoal-grey-two;
	            @include sq-transition((opacity .3s, transform .3s, background-color .3s));   
	        }

	        &:before {
	            width: pxToRem(3);
	            height: pxToRem(15);
	            margin-right: pxToRem(-1);
	            margin-top: pxToRem(-7);
	        }

	        &:after {
	            width: pxToRem(15);
	            height: pxToRem(3);
	            margin-right: pxToRem(-7);
	            margin-top: pxToRem(-1);
	        }
		}

        &.active {
            button {
		    	background-color: $teal-active;

	            &:before {
	                @include sq-transform(rotate(90deg));
	            }

	            &:after {
	                @include sq-transform(rotate(90deg));
	                opacity: 0;
	            }
            }
        }
	}

	&__text {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.5;
		padding: pxToRem(20) pxToRem(15);
		display: none;

		&.active {
			display: block;
		}

		p {
			margin: 0;
			padding-bottom: pxToRem(10);

			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}

.main {
	&.pink {
		.accordion {
			&__title {
				button {
		    		background-color: $pink;
		    		color: $charcoal-grey-two;

				    &:hover,
				    &:focus {
				    	background-color: $pink-active;	
		    			color: $white;	   	

				        &:before,
				        &:after {   	
				        	background-color: $white;
				        } 	
				    }
				}

		        &.active {
		            button {
				    	background-color: $pink-active;
		    			color: $white;	 	

				        &:before,
				        &:after {   	
				        	background-color: $white;
				        }   	
		            }
		        }
			}
		}
	}
	
	&.orange {
		.accordion {
			&__title {
				button {
		    		background-color: $orange;
		    		color: $charcoal-grey-two;

				    &:hover,
				    &:focus {
				    	background-color: $orange-active;	
		    			color: $white;	   	

				        &:before,
				        &:after {   	
				        	background-color: $white;
				        } 	
				    }
				}

		        &.active {
		            button {
				    	background-color: $orange-active;
		    			color: $white;	 	

				        &:before,
				        &:after {   	
				        	background-color: $white;
				        }   	
		            }
		        }
			}
		}
	}
	
	&.olive {
		.accordion {
			&__title {
				button {
		    		background-color: $olive;
		    		color: $charcoal-grey-two;

				    &:hover,
				    &:focus {
				    	background-color: $olive-active;	
		    			color: $charcoal-grey-two;	   	

				        &:before,
				        &:after {   	
				        	background-color: $charcoal-grey-two;
				        } 	
				    }
				}

		        &.active {
		            button {
				    	background-color: $olive-active;
		    			color: $charcoal-grey-two;	 	

				        &:before,
				        &:after {   	
				        	background-color: $charcoal-grey-two;
				        }   	
		            }
		        }
			}
		}
	}
	
	&.azure {
		.accordion {
			&__title {
				button {
		    		background-color: $azure;
		    		color: $charcoal-grey-two;

				    &:hover,
				    &:focus {
				    	background-color: $azure-active;	
		    			color: $white;	   	

				        &:before,
				        &:after {   	
				        	background-color: $white;
				        } 	
				    }
				}

		        &.active {
		            button {
				    	background-color: $azure-active;
		    			color: $white;	 	

				        &:before,
				        &:after {   	
				        	background-color: $white;
				        }   	
		            }
		        }
			}
		}
	}
	
	&.teal {
		.accordion {
			&__title {
				button {
		    		background-color: $teal;
		    		color: $charcoal-grey-two;

				    &:hover,
				    &:focus {
				    	background-color: $teal-active;	
		    			color: $charcoal-grey-two;	   	

				        &:before,
				        &:after {   	
				        	background-color: $charcoal-grey-two;
				        } 	
				    }
				}

		        &.active {
		            button {
				    	background-color: $teal-active;
		    			color: $charcoal-grey-two;	 	

				        &:before,
				        &:after {   	
				        	background-color: $charcoal-grey-two;
				        }   	
		            }
		        }
			}
		}
	}
	
	&.purple {
		.accordion {
			&__title {
				button {
		    		background-color: $purple;
		    		color: $charcoal-grey-two;

				    &:hover,
				    &:focus {
				    	background-color: $purple-active;	
		    			color: $white;	   	

				        &:before,
				        &:after {   	
				        	background-color: $white;
				        } 	
				    }
				}

		        &.active {
		            button {
				    	background-color: $purple-active;
		    			color: $white;	 	

				        &:before,
				        &:after {   	
				        	background-color: $white;
				        }   	
		            }
		        }
			}
		}
	}
}