.main {
  padding-bottom: pxToRem(55);
}

.section {
  padding: pxToRem(32) 0;

  &-full {
    padding: 0;
  }

  &__wrapper {
    padding: 0 pxToRem(34);
    max-width: pxToRem(1208); //1140 + 68
  }
}

.details {
  padding: pxToRem(24) 0;
}

h1,
.h1 {
  padding: pxToRem(24) 0;

  & + .details {
    margin-top: pxToRem(-10);
    padding-top: 0;
  }
}

h2,
.h2 {
  padding: pxToRem(15) 0;
}

h3,
.h3 {
  padding: pxToRem(10) 0;
}

h4,
.h4 {
  padding: pxToRem(10) 0;
}

h5,
.h5 {
  padding: pxToRem(10) 0;
}

.text,
section {
  p,
  div {
    &.left {
      float: left;
      width: 50%;
      padding-right: pxToRem(30);
    }

    &.right {
      float: right;
      width: 50%;
      padding-left: pxToRem(30);
    }
  }
}
