/* Module: S */
.banner-carousel {
	position: relative;
	@extend %clearfix;

	&__item {
		height: pxToRem(200);
		width: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		float: left;
		position: relative;
		overflow: hidden;
	}

	&__video {
        pointer-events: none;
        position: absolute;
        margin: auto;
        min-width: 100vw;
        min-height: 56.25vw;
        width: pxToRem(356);
        height: pxToRem(200);
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

		iframe {
	        position: absolute;
	        top: 0;
	        left: 0;
	        width: 100%;
	        height: 100%;
	        pointer-events: none;
        	opacity: 0;
		}
	}

	.slick {
		&-arrow {
			top: 50%;
			bottom: auto;
			margin-top: pxToRem(-25);
			padding: 0;
			z-index: 9;
			background-color: transparent;
			background-repeat: no-repeat;
			width: pxToRem(35);
			height: pxToRem(50);
			text-indent: -9999px;
			border: none;
			opacity: 1;
			@include sq-transition(opacity 0.3s);

			&:hover {
				opacity: 0.6;
			}
		}

		&-prev {
			left: 0;
			right: auto;
			background-image: url(../mysource_files/arrow-left.svg);
			background-position: pxToRem(10);
		}

		&-next {
			right: 0;
			left: auto;
			background-image: url(../mysource_files/arrow-right.svg);
			background-position: pxToRem(10);
		}

		&-dots {
			position: absolute;
			bottom: 0.625rem;
			left: 0;
			right: 0;
			z-index: 1;

			li {
				& + li {
					margin: 0;

				}

				&.slick-active {
					button {
						&:after {
							background-color: $white;
						}
					}
				}
			}

			button {
				-webkit-appearance: none;
				text-indent: -9999px;
				width: 0.875rem;
				height: 0.875rem;
				background-color: transparent;
				border: none;
				position: relative;
				padding: 0;

				&:after {
					content: '';
					position: absolute;
					top: 0.125rem;
					left: 0.125rem;
					width: 0.625rem;
					height: 0.625rem;
					background-color: transparent;
					border: 1px solid $white;
					border-radius: 100%;
				}
			}
		}
	}
}
