/* Module: S */
.pagination {
	padding: pxToRem(20) 0;

	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
		text-align: center;
		width: 100%;

		li {
			padding: 0;
			margin: 0;
			display: inline-block;
			color: $charcoal-grey;
			vertical-align:top;

			&.active {
				a {		
					background-color: $blueberry;
					color: $white;	
				}
			}

			&.disabled {
				color: rgba($charcoal-grey, 0.5);

				a {
					color: rgba($charcoal-grey, 0.5);

					svg {
						path {
							fill: rgba($charcoal-grey, 0.5);
						}
					}	

					&:hover,
					&:focus {
						background-color: $white-2;
						color: $charcoal-grey;

						svg {
							path {
								fill: rgba($charcoal-grey, 0.5);
							}
						}						
					}
				}
			}

			a {
				text-decoration: none;
				text-align: center;
				min-width: pxToRem(35);
				min-height: pxToRem(35);
				color: $charcoal-grey;
				font-size: 1.125em; //18
				font-weight: 900;
				line-height: 1.44;
				padding: pxToRem(5);
				display: inline-block;
				background-color: $white-2;
				@include sq-transition(background-color 0.3s, color 0.3s);
				@include font-smoothing;
				vertical-align:top;
				position: relative;

				&:hover,
				&:focus {
					background-color: $blueberry;
					color: $white;

					svg {
						path {
							fill: $white;
						}
					}
				}
			}

			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				margin: pxToRem(-10) 0 0 pxToRem(-6);

				path {
					@include sq-transition(all 0.3s);
				}
			}
		}
	}
}