/* Module: S */
.ad-banner {
	padding: pxToRem(10) 0;
	
	a {
		display: block;
		text-decoration: none;
		background-color: $white-2;
		color: $black-2;
	}

	&__info {
		padding: pxToRem(20) pxToRem(15) pxToRem(25) pxToRem(15);
	}

	&__title {
		font-size: 1.125em; //18
		font-weight: 900;
		line-height: 1.44;
		color: $charcoal-grey;
	}

	&__text {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.5;
		color: $black-2;
		padding-top: pxToRem(6);
	}
}