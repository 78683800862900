/* Module: S */
.share {
	display: none;
	text-align: right;
	margin-top: pxToRem(-10);
	padding-bottom: pxToRem(30);

	&-absolute {
		position: absolute;
		top: pxToRem(4);
		right: pxToRem(15);
	}

	a {
		background-color: $blueberry;
		font-size: 1.125em; //18
		font-weight: 900;
		display: inline-block;
		padding: pxToRem(10) pxToRem(54) pxToRem(10) pxToRem(28);
		color: $white;
		text-decoration: none;
		position: relative;
		@include font-smoothing;

		&:after {
			content: url('../mysource_files/arrow-down.svg');
			position: absolute;
			top: 10px;
			right: 20px;
		}

		&:hover {
			background-color: rgba($blueberry, 0.9);
		}
	}
}
