
/*
--------------------
Modules
--------------------
*/

html {
    &.noscroll {
        overflow-y: auto;
        
        body {
            overflow-y: auto;
        }
    }
}

.main {
    &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: pxToRem(187);
        height: pxToRem(100);
        background: url(../mysource_files/bg-main.png) 0 0 no-repeat;
        z-index: -1;
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: pxToRem(187);
        height: pxToRem(100);
        background: url(../mysource_files/bg-main.png) 0 0 no-repeat;
        z-index: -1;
    }
}

.section {
	padding: pxToRem(40) 0;

    &-full {
        padding: 0;
    }

    &__wrapper {
        padding: 0 pxToRem(40);
        max-width: pxToRem(1220); //1140 + 80  
    }
}

.row {
    margin: 0 pxToRem(-15);
    
	.column {
		float: left;	
        padding-left: pxToRem(15);
        padding-right: pxToRem(15);
        width: 100%;

		&-75 {
			width: 75%;
		}

		&-50 {
			width: 50%;
		}

		&-25 {
			width: 25%;

            &:nth-child(3) {
                clear: left;
            }

            &.column-right {
                clear: none;
            }
		}

        &-right {
            float: right;
            min-height: 100vh;
            
            h1 {
                padding-right: pxToRem(155);
            }
        }

        &-section {
            padding-top: 0;
            padding-bottom: 0;
        }

		&-offset-left {
			padding-left: pxToRem(30);
		}

		&-offset-right {
			padding-right: pxToRem(30);
		}
	}
}

h1,
.h1 {
    padding-top: pxToRem(10);
}

.wide-only {
    display: block;
}