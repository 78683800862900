/* Module: S */
.banner-carousel {

	&__item {
		height: pxToRem(480);
	}

	&__video {
        width: pxToRem(854);
        height: pxToRem(480);
    }

	.slick {
		&-arrow {
			width: pxToRem(50);
			height: pxToRem(70);
			background-size: pxToRem(30);
			margin-top: pxToRem(-35);
		}

		&-prev {
			left: pxToRem(20);
		}

		&-next {
			right: pxToRem(20);
		}

		&-dots {
			bottom: 2.375rem;

			li {
				&.slick-active {
					button {
						&:after {
							top: 0.188rem;
							left: 0.188rem;
							width: 1.125em;
							height: 1.125em;
						}
					}
				}
			}

			button {
				width: 1.5rem;
				height: 1.5rem;

				&:after {
					top: 0.313rem;
					left: 0.313rem;
					width: 0.875rem;
					height: 0.875rem;
					border: 2px solid $white;
				}
			}
		}
	}
}