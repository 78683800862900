/* Module: S */
.form {
	@extend %clearfix;

	label,
	legend,
	.title {
		font-size: 1.125em; //18
		font-weight: 900;
		color: $white;
		@include font-smoothing;
		padding-bottom: pxToRem(6);
		display: block;

		a {
			color: $black-2;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&__loader {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
		background-color: rgba($white, 0.5);
		display: none;
	}

	&__spinner {
		width: pxToRem(50);
		height: pxToRem(40);
		text-align: center;
		font-size: pxToRem(10);
		position: fixed;
		top: 50%;
		left: 50%;
		margin: pxToRem(-20) 0 0 pxToRem(-25);

		div {
			background-color: $blueberry;
			height: 100%;
			width: pxToRem(6);
			display: inline-block;

			-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
			animation: sk-stretchdelay 1.2s infinite ease-in-out;

			&.form {
				&__spinner {
					&-2 {
						-webkit-animation-delay: -1.1s;
						animation-delay: -1.1s;
					}

					&-3 {
						-webkit-animation-delay: -1.0s;
						animation-delay: -1.0s;
					}

					&-4 {
						-webkit-animation-delay: -0.9s;
						animation-delay: -0.9s;
					}

					&-5 {
						-webkit-animation-delay: -0.8s;
						animation-delay: -0.8s;
					}
				}
			}
		}


	}

	&__tooltip {
		font-size: 0.875em; //14
		font-weight: 900;
		color: $black-3;
		@include font-smoothing;
		margin-top: pxToRem(-4);
		padding-bottom: pxToRem(10);
	}

	&__error {
		font-size: 0.875em; //14
		font-weight: 900;
		color: $cherry-red;
		@include font-smoothing;
		margin-top: pxToRem(-4);
		padding-bottom: pxToRem(10);

		&-technical {
			font-size: 1.125em; //18
			font-weight: 900;
			color: $cherry-red;
			@include font-smoothing;
			padding: pxToRem(20) 0;
			display: none;

		}

		a {
			color: $cherry-red;
		}
	}

	&__text {
		font-size: 0.875em; //14
		font-weight: 900;
		@include font-smoothing;
		padding: pxToRem(20) 0 pxToRem(10) 0;

		&-required {
			color: $cherry-red;
		}
	}

	hr {
		border: pxToRem(20) solid transparent;
        background-image: url(../mysource_files/bg-lines.png);
        background-repeat: repeat;
        margin: pxToRem(40) 0;
	}

	input {
		&::-ms-clear {
		    display: none;
		}
	}

	input[type="text"] {
        font-size: 1em; //16
		color: $black-2;
		font-weight: 300;
		padding: pxToRem(14) pxToRem(60) pxToRem(14) pxToRem(15);
		width: 100%;
		border: none;
	}

	input[type="radio"] {
		display: none;

		&:checked{
			& + label {
				&:after {
					content: '';
					width: pxToRem(12);
					height: pxToRem(12);
					border-radius: 100%;
					position: absolute;
					left: pxToRem(8);
					top: pxToRem(16);
					background-color: $charcoal-grey;
				}
			}
		}

		& + label {
			position: relative;
			padding: pxToRem(12) 0 pxToRem(12) pxToRem(46);
			display: inline-block;
			font-size: 1em; //16
			font-weight: normal;

			&:before {
				content: '';
				width: pxToRem(28);
				height: pxToRem(28);
				border-radius: 100%;
				position: absolute;
				left: 0;
				top: pxToRem(8);
				background-color: $white-2;
			}
		}
	}

	input[type="checkbox"] {
		display: none;

		&:checked{
			& + label {
				&:after {
					content: '';
					width: pxToRem(28);
					height: pxToRem(28);
					position: absolute;
					left: 0;
					top: pxToRem(8);
					background: transparent url(../mysource_files/tick.svg) center center no-repeat;
					background-size: pxToRem(16);
				}
			}
		}

		& + label {
			position: relative;
			padding: pxToRem(12) 0 pxToRem(12) pxToRem(46);
			display: inline-block;
			font-size: 1em; //16
			font-weight: normal;

			&:before {
				content: '';
				width: pxToRem(28);
				height: pxToRem(28);
				position: absolute;
				left: 0;
				top: pxToRem(8);
				background-color: $white-2;
			}
		}
	}

	textarea {
		background-color: $white-2;
		resize: none;
		width: 100%;
		min-height: pxToRem(260);
		border: none;
		padding: pxToRem(14) pxToRem(60) pxToRem(14) pxToRem(15);
	}


	input[type="file"] {
	    width: 0.1px;
	    height: 0.1px;
	    opacity: 0;
	    overflow: hidden;
	    position: absolute;
	    z-index: -1;

	    & + label {
	    	font-size: 0.938em; //15
			font-weight: 900;
			white-space: nowrap;
			cursor: pointer;
			display: block;
			float: left;
			overflow: hidden;
			padding: pxToRem(14) pxToRem(43) pxToRem(15) pxToRem(43);
			color: $charcoal-grey;
			background-color: $pinkish-grey;
		}


		&:focus,
		&.has-focus {
		    & + label {
				outline: 1px dotted #000;
				outline: -webkit-focus-ring-color auto 5px;
			}
		}
	}

	fieldset {
		border: none;
		padding: 0;
		margin: 0;
	}

	&__table {
		th {
			font-size: 1em; //16
			font-weight: 300;
		}

		td {
			padding: 0 pxToRem(20);

			input[type="radio"],
			input[type="checkbox"] {
				& + label {
					font-size: 1.125em; //18
					padding-top: pxToRem(10);
					padding-bottom: pxToRem(10);
				}
			}
		}

		thead {
			th {
				padding: pxToRem(10);
			}
		}

		tbody {
			th {
				padding-right: pxToRem(10);
			}
		}
	}

	&__info {
		display: block;
		float: left;
		font-size: 0.938em; //15
		font-weight: 300;
		color: $charcoal-grey;
		padding: pxToRem(14) 0 pxToRem(15) pxToRem(20);
	}

	&__field {
		&-wrapper {
			position: relative;
			@extend %clearfix;
		}

		>input[type="submit"] {
			border: none;
			border-radius: 0;
			background-color: $blueberry;
			color: $white;
			font-size: 0.938em; //15
			font-weight: 900;
			padding: pxToRem(14) pxToRem(50) pxToRem(15) pxToRem(50);
			@include clear-default-appearance;
			@include font-smoothing;
			@include sq-transition(background-color 0.3s);

			&:hover,
			&:focus {
				background-color: $blueberry-light;
			}
		}

		&-select {
			.form__field-wrapper {
				position: relative;
				background-color: $white;

				&:hover,
				&:focus {
					&:after {
						background-color: rgba($charcoal-grey-two, 0.15);
					}
				}

				&:after {
					content: '';
					background-color: $light-grey;
					background-image: url(../mysource_files/arrow-down-dark.svg);
					background-position: center center;
					background-repeat: no-repeat;
					background-size: pxToRem(23);
					padding: pxToRem(13) pxToRem(15);
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					display: block;
					line-height: initial;
					width: pxToRem(50);
					@include sq-transition(background-color 0.3s);
				}
			}

			select {
		        font-size: 1em; //16
				color: $black-2;
				font-weight: 300;
				background-color: transparent;
				padding: pxToRem(14) pxToRem(60) pxToRem(14) pxToRem(15);
				width: 100%;
				border: none;
				border-radius: 0;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				position: relative;
				z-index: 1;

				&::-ms-expand {
				    display: none; /* remove default arrow in IE 10 and 11 */
				}
			}
		}

		&-time {
			@extend %clearfix;

			.form {
				&__field {
					width: pxToRem(110);
					float: left;
				}

				&__spacer {
					float: left;
					padding: pxToRem(26) pxToRem(10);
					min-height: pxToRem(74);
				}
			}

			>.form {
				&__field {
					&-wrapper {
						margin-top: pxToRem(-12);
					}
				}
			}
		}

		&.error {
			.form__error {
				display: block;
			}

			input[type="text"],
			textarea,
			select {
				border: 1px solid $cherry-red;
			}

			.form__field-wrapper {
				>input[type="checkbox"],
				>input[type="radio"] {
					& + label {
						&:before {
							border: 1px solid $cherry-red;
						}
					}
				}

			}

			.ui-datepicker-trigger {
				border: 1px solid $cherry-red;
				border-left: none;
			}

		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;

			li {
				padding: 0;
				margin: 0;
			}
		}
	}


	&__capcha {
		img,
		input[type="text"] {
			width: 9em;
		}
	}

	&__controls {
		input[type="submit"] {
			border: none;
			border-radius: 0;
			background-color: $blueberry;
			color: $white;
			font-size: 0.938em; //15
			font-weight: 900;
			padding: pxToRem(14) pxToRem(50) pxToRem(15) pxToRem(50);
			@include clear-default-appearance;
			@include font-smoothing;
			@include sq-transition(background-color 0.3s);

			&:hover,
			&:focus {
				background-color: $blueberry-light;
			}
		}

		input[type="reset"] {
			border: none;
			border-radius: 0;
			background-color: transparent;
			color: $white;
			font-size: 0.938em; //15
			font-weight: 900;
			text-decoration: underline;
			padding: pxToRem(14) pxToRem(15) pxToRem(15) pxToRem(15);
			margin-right: pxToRem(12);
			@include clear-default-appearance;
			@include font-smoothing;

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}

	&-validation {
		.form {
			&__error {
				display: none;
			}
		}
	}

	&-page {
		label,
		legend,
		.title{
			color: $charcoal-grey;
		}

		input[type="text"] {
			background-color: $white-2;
		}

		.form {
			&__field {
				padding: pxToRem(12) 0;

				&-select {
					.form__field-wrapper {
						background-color: $white-2;
						max-width: pxToRem(457);
					}
				}

				&-date {
					.form__field-wrapper {
						max-width: pxToRem(457);
					}
				}

				&-time {
					padding-bottom: 0;
				}
			}
		}

	}

	abbr[title] {
		border: none;
		text-decoration: none;
	}
}

.ui-datepicker {
    background-color: $white-2;
	padding: 0 pxToRem(24) pxToRem(14) pxToRem(24);
	display: none;
    &-trigger {
    	position: absolute;
    	top: 0;
    	bottom: 0;
    	right: 0;
		border: none;
		border-radius: 0;
		background-color: $light-grey;
		background-image: url(../mysource_files/calendar.svg);
		background-position: center center;
		background-repeat: no-repeat;
		background-size: pxToRem(23);
		text-indent: -99999px;
		padding: pxToRem(13) pxToRem(15);
		@include clear-default-appearance;
		padding: 0;
		width: pxToRem(50);
		@include sq-transition(background-color 0.3s);

		&:hover,
		&:focus {
			background-color: rgba($charcoal-grey-two, 0.15);
		}
    }

    &-calendar {
		text-align: center;

		td {
			min-width: pxToRem(28);
			border: none;
			padding: 0;
		}
    }

    &-header {
		padding-bottom: pxToRem(24);
    }

    &-title {
    	font-size: 0.938em; //15
		font-weight: 900;
		text-align: center;
		padding: pxToRem(6) pxToRem(10);
    }

    .ui-state {
    	&-default {
	    	font-size: 0.938em; //15
			font-weight: 300;
			line-height: 1.5;
			padding: pxToRem(3) 0;
			color: $charcoal-grey-two;
			text-decoration: none;
			display: block;
			@include sq-transition(background-color 0.3s, color 0.3s);

			&:hover {
		    	background-color: $blueberry;
		    	color: $white;
			}
	    }
	}

	&-buttonpane {
    	float: right;
    	padding-top: pxToRem(8);

		.ui-datepicker {
		    &-close,
		    &-current {
		    	display: none;
		    }
		}
	}

	.ui-icon {
		display: block;
		text-indent: -9999px;
		width: pxToRem(34);
		height: pxToRem(34);
        background-image: url(../mysource_files/arrow-left.svg);
        background-size: pxToRem(10);
        background-position: center;
        background-repeat: no-repeat;
	}

    &-prev,
    &-next {
    	position: absolute;
    	top: 0;
    	left: 0;
		background-color: $blueberry;

    	&:hover,
    	&:focus {
			background-color: rgba($blueberry, 0.8);
    	}
    }

    &-next {
    	left: auto;
    	right: 0;

    	.ui-icon {
        	background-image: url(../mysource_files/arrow-right.svg);
    	}
    }

    &-unselectable {
    	opacity: 0.4;

    	.ui-state {
	    	&-default {
				&:hover {
			    	background-color: transparent;
					color: $charcoal-grey-two;
				}
		    }
		}
    }

	&-ready {
	    .ui-state {
		    &-active {
		    	background-color: $blueberry;
		    	color: $white;
		    }
		}
	}
}

@-webkit-keyframes sk-stretchdelay {
	0%, 40%, 100% {
		-webkit-transform: scaleY(0.4)
	}

	20% {
		-webkit-transform: scaleY(1.0)
	}
}

@keyframes sk-stretchdelay {
	0%, 40%, 100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}

	20% {
		transform: scaleY(1.0);
		-webkit-transform: scaleY(1.0);
	}
}

.sq-calendar-date-table {
	width: 100%;


	input[type="text"] {
		width: auto;
	}
}
