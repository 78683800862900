/* Module: S */
.tile-list {
	width: pxToRem(990);
	margin: 0 auto;
	padding: pxToRem(20) 0 0 0;

	&__item {
		&:nth-child(2n+1) {
			clear: none;
		}	
		
		&:nth-child(5n+1) {
			clear: left;
		}	

		&-more {
			padding-top: pxToRem(50);
		}
	}
}