/* Module: S */
.page-wrapper {
	padding-top: pxToRem(126);
}

.header {
	&__wrapper {
		height: pxToRem(126);
		padding: pxToRem(29) pxToRem(34) pxToRem(27) pxToRem(34);
	}

	&__logo {
		img {
			width: pxToRem(150);
		}
	}

	&__social {
		padding-top: pxToRem(14);
		padding-right: pxToRem(256);

		ul {
			li {
				a {
					img {
						width: pxToRem(45);
						height: pxToRem(45);
					}
				}
			}
		}
	}

	&__search {
		display: block;
		top: pxToRem(43);
		right: pxToRem(34);
		left: auto;
		width: pxToRem(242);
		background-color: transparent;
		padding: 0;
		height: pxToRem(45);

		form {
			top: 0;
			right: 0;
			left: 0;
		}

		&-toggle {
			display: none;
		}
	}
}