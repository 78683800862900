/* Module: S */
.footer {
  color: $white;
  background-color: $charcoal-grey-two;
  @include font-smoothing;
  padding: pxToRem(20) 0;

  &:after {
    content: "";
    position: absolute;
    bottom: pxToRem(70);
    left: 0;
    right: 0;
    height: pxToRem(20);
    background-image: url(../mysource_files/bg-lines.png);
    background-repeat: repeat;
    opacity: 0.1;
  }

  ul {
    margin: 0;
    list-style-type: none;
  }

  li {
    margin: 0;
    padding: 0;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__wrapper {
    padding: 0 pxToRem(10);
    max-width: pxToRem(1160); //1140 + 20
    margin: 0 auto;
  }

  &__columns {
    @extend %clearfix;
  }

  &__column {
    width: 100%;
    float: left;
  }

  &__title {
    font-size: 1.375em; //22
    font-weight: 900;
    color: $pinkish-grey;
    text-transform: uppercase;
    border-bottom: 1px solid $warm-grey;
    padding: pxToRem(12) 0;
  }

  &__caption {
    font-size: 1.125em; //18
    font-weight: 900;
    padding: pxToRem(4) 0;
  }

  &__info {
    font-weight: 300;
    padding-top: pxToRem(16);

    p {
      padding: 0;
      margin: 0;
      font-size: 0.938em; //15
      line-height: 1.7;
    }

    a {
      color: $white;
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &__list {
    padding: pxToRem(24) 0;

    li {
      font-size: 0.938em; //15
      font-weight: 900;
      line-height: 2;
    }
  }

  &__text {
    padding: pxToRem(24) 0;

    p {
      font-size: 0.938em; //15
      font-weight: 900;
      line-height: 2;
      padding: 0;
      margin: 0;
    }
  }

  &__icon-list {
    padding: pxToRem(6) 0;

    li {
      padding: pxToRem(12) 0;

      &.address,
      &.phone,
      &.email,
      &.post,
      &.fax,
      &.abn {
        background-position: 0 pxToRem(12);
        background-repeat: no-repeat;

        .footer {
          &__caption {
            padding-left: pxToRem(50);
          }
        }
      }

      &.address {
        background-image: url(../mysource_files/contact-address.svg);
      }

      &.phone {
        background-image: url(../mysource_files/contact-phone.svg);
      }

      &.email {
        background-image: url(../mysource_files/contact-email.svg);
      }

      &.post {
        background-image: url(../mysource_files/contact-post.svg);
      }

      &.fax {
        background-image: url(../mysource_files/contact-fax.svg);
      }

      &.abn {
        background-image: url(../mysource_files/contact-abn.svg);
      }
    }
  }

  &__icon-list {
    padding: pxToRem(6) 0;

    li {
      padding: pxToRem(12) 0;
      position: relative;
    }
  }

  &__icon {
    position: absolute;
    top: pxToRem(12);
    left: 0;

    img {
      width: auto;
      max-width: pxToRem(40);
      max-height: pxToRem(40);
    }

    & + .footer__caption {
      padding-left: pxToRem(50);
    }
  }

  &__logo {
    padding: pxToRem(20) 0;

    img {
      max-width: pxToRem(267);
    }
  }

  &__links {
    padding-top: pxToRem(30);

    ul {
      padding: 0;

      li {
        font-size: 0.875em; //14
        font-weight: 300;
        line-height: 2;
        display: inline-block;

        &:after {
          content: "|";
          padding: 0 pxToRem(6);
          display: inline-block;
        }

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__social {
    padding: pxToRem(24) 0;

    ul {
      padding: 0;
      margin: 0 pxToRem(-5);
      list-style-type: none;
      @extend %clearfix;

      li {
        float: left;
        padding: 0;
        margin: 0;

        a {
          padding: pxToRem(5);
          overflow: hidden;
          display: block;
          @include sq-transition(opacity 0.3s);

          &:hover,
          &:focus {
            opacity: 0.8;
          }

          img {
            width: pxToRem(30);
            height: pxToRem(30);
          }
        }
      }
    }
  }

  &__translate {
    padding: pxToRem(29) 0;

    img {
      display: inline;
      width: auto;
    }

    .goog-te-gadget-simple {
      border: none;
      padding: pxToRem(6) pxToRem(5) pxToRem(7) pxToRem(8);
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
