/* Module: S */
.tile-list {
	width: pxToRem(396);
	margin: 0 auto;

	&__item {
		padding: pxToRem(20) pxToRem(14);
		width: pxToRem(198);

		&:nth-child(2n+1) {
			clear: left;
		}
	}

	&__image {
		width: pxToRem(170);
		height: pxToRem(170);
		margin-right: 0;
		margin-bottom: pxToRem(14);
	}

	&__info {
		min-height: 0;
		text-align: center;
		width: 100%;   
	}
}