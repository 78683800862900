/* Module: S */
.article-list {
	&__item {
		padding: pxToRem(20) 0;
	}

	a {
		text-decoration: none;
		display: block;
        @extend %clearfix;

		&:hover,
		&:focus {
			.article-list {
				&__image {
					img {
						@include sq-transform(scale(1.1));	
					}
				}

				&__title {
					color: rgba($black-3, 0.8);
					text-decoration: underline;
				}
			}
		}
	}
	
	&__image {
		width: 100%;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		position: relative;

		&-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			@include sq-transform(scale(1));
			@include sq-transition(all 0.3s);
		}
	}

	&__info {
		padding-bottom: pxToRem(4);

		>div {
			display: inline;
			color: $battleship-grey;
			font-size: 0.875em; //14
			font-weight: 900;
			padding-right: pxToRem(8);

			&:after {
				content: '|';
				padding-left: pxToRem(8); 
			}

			&:last-child {
				padding-right: 0;

				&:after {
					display: none;
				}
			}
		}

	}

	&__title {
		font-size: 1.125em; //18
		font-weight: 900;
		line-height: 1.44;
		color: $black-3;
		@include sq-transition(color 0.3s);	
	}

	&__text {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.5;	
		color: $black-2;
		padding-top: pxToRem(6);
	}

	.date__error {
		margin: 0;
		color: #e60035;
		font-size: 0.875em;
		font-weight: 600;
		font-size: 1rem;
	}
}