/* Module: S */
.gallery {	
	padding: pxToRem(30) 0;

	&__main {
		.slick {
			&-arrow {
				display: none!important;
			}
		}
	}

	&__nav {
		display: block;
	}
}