/* Module: S */
.event-list {
	margin-top: pxToRem(-25);
	padding: 0 pxToRem(40) pxToRem(40) pxToRem(40);

	&__item {
		width: 16.666%;
		&-large {
			width: 33.33%;
		}

		&-single {
			width: 100%;
			
			a {
        		padding-bottom: pxToRem(190);
			}
		}
	}

	&-featured {
		.event-list {
			&__item {
			    &:nth-child(10) {
			    	clear: left;
			    }

			    &:nth-child(4) {
			    	clear: none;
			    }
			}
		}
	}
}