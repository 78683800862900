/* Module: S */
.article-list {
	padding: pxToRem(10) 0;

	&__image {
		width: pxToRem(194);
		padding-bottom: pxToRem(109);
		float: left;
		margin-bottom: 0;
		margin-right: pxToRem(24);

		&+.article-list__content {
			padding-left: pxToRem(218);
		}
	}

	&__info {
		padding-top: 0;
	}

}