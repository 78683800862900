/* Module: S */
.responsive-table {
	margin: pxToRem(30) 0;

    td {
        span {
        	padding: pxToRem(20);
        }
    }
}

.js-responsive-table {
    td {
        &:before {
            width: 35%;
            padding: pxToRem(20);
        }

        &:after {
            width: 35%;
        }

        span {
            width: 65%;
        }
    }
}