/* Module: S */
.tile-list {
  @extend %clearfix;
  margin: pxToRem(-5) 0;

  &__item {
    padding: pxToRem(5) 0;
    width: 100%;
    float: left;
    display: none;

    &:nth-child(-n + 10) {
      display: block;
    }

    &-more {
      width: 100%;
      text-align: center;
      float: left;
      padding-top: pxToRem(30);

      button {
        color: $battleship-grey;
        font-size: 1.125em; //18
        font-weight: 900;
        border: none;
        background-color: transparent;
        text-transform: uppercase;
        @include font-smoothing;
        @include sq-transition(color 0.3s);

        &:hover {
          color: $black-3;

          svg {
            path {
              fill: $black-3;
            }
          }
        }

        svg {
          path {
            @include sq-transition(fill 0.3s);
          }
        }

        span {
          padding-right: pxToRem(10);
        }
      }
    }
  }

  &--quicklinks {
    .tile-list__item {
      /* Override default display of 10 items by 5 items */
      &:nth-child(-n + 10) {
        display: none;
      }
      &:nth-child(-n + 5) {
        display: block;
      }
    }
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      .tile-list {
        &__image {
          &-wrapper {
            @include sq-transform(scale(1.1));
          }
        }

        &__title {
          color: rgba($black-3, 0.8);
          text-decoration: underline;
        }
      }
    }
  }

  &__image {
    width: pxToRem(60);
    height: pxToRem(60);
    margin-right: pxToRem(10);
    overflow: hidden;
    position: relative;
    float: left;

    &-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      @include sq-transform(scale(1));
      @include sq-transition(all 0.3s);
    }
  }

  &__info {
    min-height: pxToRem(60);
    @include sq-flex-box;
    @include sq-justify-content(center);
    @include sq-flex-direction(column);
  }

  &__title {
    font-size: 1.125em; //18
    font-weight: 900;
    line-height: 1.44;
    color: $black-3;
    @include sq-transition(color 0.3s);
  }
}
