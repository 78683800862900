/* Module: S */
.back-to-top {	
	button {
		width: pxToRem(90);
		height: pxToRem(100);

		svg {
			width: pxToRem(33);
			height: pxToRem(19);
		}
	}
}