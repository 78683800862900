/* Module: S */
.back-to-top {	
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 1;

	&.fixed {
		position: fixed;
		-webkit-transform: translateZ(0);
	}

	button {
		background-color: $blueberry;
		font-size: 1.125em; //18
		font-weight: 900;
        border: none;
        text-transform: uppercase;
        @include font-smoothing; 
		@include sq-transition(background-color 0.3s);
		border-radius: 0;
		width: pxToRem(50);
		height: pxToRem(55);
		padding: 0;

        &:hover {
        	background-color: rgba($blueberry, 0.9);
        }
	}
}