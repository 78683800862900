/* Module: S */
.themes {
    ul {
        margin: 0 pxToRem(-15);
    }
    
    li {
        width: 33.333%;
        padding: pxToRem(15);
    }
}