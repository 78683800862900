/* Module: S */
.page-wrapper {
	padding-top: pxToRem(181);
}

.header {
	&__inner {
		margin-bottom: pxToRem(55);
	}

	&__wrapper {
		padding: pxToRem(29) pxToRem(40) pxToRem(27) pxToRem(40);
        max-width: pxToRem(1220); //1140 + 80  
        margin: 0 auto;
	}

    &__accessibility {
    	top: 0;
    	left: pxToRem(40);

    	div {
    		a {
    			color: $white;
    		}
    	}
    }

	&__toggle {
		display: none;
	}

	&__navigation {
		position: absolute;
		bottom: pxToRem(-55);
		left: pxToRem(40);
		right: pxToRem(40);
	}

	&__social {
		padding-right: pxToRem(276);
	}

	&__search {
		right: pxToRem(40);
		width: pxToRem(262);
	}
}