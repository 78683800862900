/* Module: S */
.banner-links {
	padding: 0;

	&__wrapper {
		position: absolute;
		top: 0;
		height: pxToRem(480);
	    left: 0;
	    right: 0;
	    padding: 0 pxToRem(132);
	    z-index: 1;
	    @include sq-flex-box;
	    @include sq-justify-content(center);
	    @include sq-flex-direction(column);	    
	}

	ul {
		max-width: 100%;

		li {
			&:nth-child(3n+1) {
				clear: left;
			}
		}
	}
}