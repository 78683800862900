/* Module: S */
.themes {
    width: 100%;

    ul {
        padding: 0;
        margin: 0 pxToRem(-10);
        @extend %clearfix;
        width: 100%;
        display: block;
    }

    li {
        list-style-type: none;
        margin: 0;
        display: block;
        float: left;    
        width: 50%;
        padding: pxToRem(10);

        &.active {
            button {
                border-color: $charcoal-grey-two;
            }
        }

        button {
            background-color: transparent;
            border: 2px solid $white;
            border-radius: 0;
            -webkit-appearance: none;
            width: 100%;
            text-align: center;
            padding: 0;

            span {
                display: block;
                width: 100%;
                height: pxToRem(100);
                font-size: 1.125em; //18
                font-weight: bold;
                padding: pxToRem(60) 0 0 0;
                @inclide font-smoothing;
            }            
        }

        &.pink {
            button {
                span {
                    &:first-child {
                        background-color: $pink;
                    }

                    &:last-child {
                        background-color: $pink-active;
                        color: $white;
                    }
                }
            }
        }
        
        &.orange {
            button {
                span {
                    &:first-child {
                        background-color: $orange;
                    }

                    &:last-child {
                        background-color: $orange-active;
                        color: $white;
                    }
                }
            }
        }
        
        &.olive {
            button {
                span {
                    &:first-child {
                        background-color: $olive;
                    }

                    &:last-child {
                        background-color: $olive-active;
                        color: $charcoal-grey-two;
                    }
                }
            }
        }
        
        &.azure {
            button {
                span {
                    &:first-child {
                        background-color: $azure;
                    }

                    &:last-child {
                        background-color: $azure-active;
                        color: $white;
                    }
                }
            }
        }
        
        &.teal {
            button {
                span {
                    &:first-child {
                        background-color: $teal;
                    }

                    &:last-child {
                        background-color: $teal-active;
                        color: $charcoal-grey-two;
                    }
                }
            }
        }
        
        &.purple {
            button {
                span {
                    &:first-child {
                        background-color: $purple;
                    }

                    &:last-child {
                        background-color: $purple-active;
                        color: $white;
                    }
                }
            }
        }
    }
}