/* Module: S */
.footer {
    &:after {
        bottom: pxToRem(90);
    }

    &__wrapper {
        padding: 0 pxToRem(40);
        max-width: pxToRem(1220); //1140 + 80  
    }

    &__columns {
        margin: 0 pxToRem(-15);
        padding: pxToRem(35) 0;
    }

    &__column {
        width: 33.33%;
        padding: 0 pxToRem(15);
    }

    &__logo {
        padding: 0;
        position: relative;

        a {
            display: block;
            position: absolute;
            bottom: pxToRem(40);
            right: 0;
        }

        img {
            max-width: pxToRem(359);
        }
    }

    &__links {
        padding: pxToRem(40) 0 pxToRem(10) 0;

        ul {
            text-align: right;
        }
    }

    &__social {
        ul {
            li {
                a {
                    img {
                        width: pxToRem(32);
                        height: pxToRem(32);
                    }
                }
            }
        }
    }
}