/* Module: S */
.tabs {
	padding: pxToRem(20) 0;

	&__filters{
	    position: relative;
	    @extend %clearfix;	
        height: pxToRem(45);
        overflow: hidden;
    	
	}

    &__filters-wrapper {
        position: relative;
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        padding-bottom: pxToRem(45);

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__filters-content {
        float: left;
        @include sq-transition(transform .3s ease-in-out);

        &-no-transition {
        	@include sq-transition(none);
        }
    }

    &__button {
        position: absolute;
        top: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        @include sq-transition(opacity .3s, visibility .3s);
        appearance: none;
        background-color: $white;
        padding: 0;
        border: 0;
        border-radius: 0;
        width: pxToRem(45);

        &:hover {
            cursor: pointer;
        }

        &-left {
            left: 0;
            background: -webkit-linear-gradient(right, rgba($white,0) 0%, rgba($white,0.9) 100%);
            background: -o-linear-gradient(left, rgba($white,0) 0%, rgba($white,0.9) 100%);
            background: -moz-linear-gradient(left, rgba($white,0) 0%, rgba($white,0.9) 100%);
            background: linear-gradient(to left, rgba($white,0) 0%, rgba($white,0.9) 100%);

            [data-overflowing="both"] ~ &,
            [data-overflowing="left"] ~ & {
                opacity: 1;
                visibility: visible;
            }
        }

        &-right {
            right: 0;
            background: -webkit-linear-gradient(left, rgba($white,0) 0%, rgba($white,0.9) 100%);
            background: -o-linear-gradient(right, rgba($white,0) 0%, rgba($white,0.9) 100%);
            background: -moz-linear-gradient(right, rgba($white,0) 0%, rgba($white,0.9) 100%);
            background: linear-gradient(to right, rgba($white,0) 0%, rgba($white,0.9) 100%);

            [data-overflowing="both"]  ~ &,
            [data-overflowing="right"] ~ & {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__filter {
        display: inline-flex;
        align-items: center;

        a {
            display: block;
            float: left;
            position: relative;
            text-decoration: none;
            background-color: $teal;
            color: $charcoal-grey-two;
            font-size: 1.125em; //18
			font-weight: 900;
			line-height: 1.44;
			padding: pxToRem(10) pxToRem(20);
			@include sq-transition(background-color 0.3s, color 0.3s);
            @include font-smoothing;

			&:hover,
			&:focus {
                background-color: $teal-active;				
			}
        }

        &.active {
            a {
                background-color: $teal-active;
            }
        }
    }

    &__panels {
        background-color: $white-2;
    }

    &__panel {
        display: none;
        padding: pxToRem(15) pxToRem(15) pxToRem(30) pxToRem(15);
        font-size: 1em; //16
        font-weight: 200;
		line-height: 1.5;

        &.active {
            display: block;
        }

        p {
        	margin: 0;
        	padding-bottom: pxToRem(10);
        }
    }
}

.main {
    &.pink {
        .tabs {
            &__filter {
                a {
                    background-color: $pink;

                    &:hover,
                    &:focus {
                        background-color: $pink-active;     
                        color: $white;           
                    }
                }

                &.active {
                    a {
                        background-color: $pink-active;     
                        color: $white;   
                    }
                }
            }
        }
    }

    &.orange {
        .tabs {
            &__filter {
                a {
                    background-color: $orange;

                    &:hover,
                    &:focus {
                        background-color: $orange-active;     
                        color: $white;           
                    }
                }

                &.active {
                    a {
                        background-color: $orange-active;     
                        color: $white;   
                    }
                }
            }
        }
    }
    
    &.olive {
        .tabs {
            &__filter {
                a {
                    background-color: $olive;

                    &:hover,
                    &:focus {
                        background-color: $olive-active;     
                        color: $white;           
                    }
                }

                &.active {
                    a {
                        background-color: $olive-active;     
                        color: $white;   
                    }
                }
            }
        }
    }
    
    &.azure {
        .tabs {
            &__filter {
                a {
                    background-color: $azure;

                    &:hover,
                    &:focus {
                        background-color: $azure-active;     
                        color: $white;           
                    }
                }

                &.active {
                    a {
                        background-color: $azure-active;     
                        color: $white;   
                    }
                }
            }
        }
    }
    
    &.teal {
        .tabs {
            &__filter {
                a {
                    background-color: $teal;

                    &:hover,
                    &:focus {
                        background-color: $teal-active;     
                        color: $white;           
                    }
                }

                &.active {
                    a {
                        background-color: $teal-active;     
                        color: $white;   
                    }
                }
            }
        }
    }
    
    &.purple {
        .tabs {
            &__filter {
                a {
                    background-color: $purple;

                    &:hover,
                    &:focus {
                        background-color: $purple-active;     
                        color: $white;           
                    }
                }

                &.active {
                    a {
                        background-color: $purple-active;     
                        color: $white;   
                    }
                }
            }
        }
    }
}