/* Module: S */
.info-title {
    padding: pxToRem(12) 0;
}

.info-block {
    padding: pxToRem(10) 0;

    &__title {
        padding: pxToRem(10) pxToRem(20);
    }

    &__content {
        padding: pxToRem(20); 

        p {
            padding: 0 0 pxToRem(20) 0;

            &:last-child {
                padding: 0;
            }   
        }
    }
}