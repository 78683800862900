/* Module: S */
.pullout {
	top: pxToRem(200);
	right: pxToRem(-325);

	&.active {
		right: 0;

		.pullout {
			&__toggle {
				button {
					&:after {
						padding: pxToRem(8) pxToRem(14) pxToRem(8) pxToRem(45);
					}			
				}
			}
		}
	}

	&__toggle {
		top: pxToRem(65);
		left: pxToRem(-111);
		width: pxToRem(176);
		height: pxToRem(46);

		button {
			font-size: pxToRem(22);
			padding: pxToRem(8) pxToRem(14) pxToRem(8) pxToRem(45);
		}
	}

	&__content {
		width: pxToRem(325);
	}

	&__text {
		li {
			a {
				padding: pxToRem(15) 0 pxToRem(15) pxToRem(40);
				font-size: pxToRem(20);
				line-height: 1.2;
				border-bottom-width: 2px;
			}
		}
	}

	&__title {
		font-size: pxToRem(23);
	}
}