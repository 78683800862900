/* Module: S */
.banner-links {
	padding: pxToRem(20) 0;
	@include font-smoothing;

	&__wrapper {
		@extend %clearfix;
	}

	h2 {
		font-size: 1.375em; //22
		text-transform: uppercase;
		background-color: $charcoal-grey-two;
		color: $white;
		padding: pxToRem(15) pxToRem(14);
		float: left;
	}

	ul {
		list-style-type: none;
		padding: pxToRem(12) 0 0 0;
		margin: 0 pxToRem(-2);
		float: left;
		width: 100%;

		li {
			padding: 0;
			margin: 0;
			display: block;
			float: left;
			padding: pxToRem(2) pxToRem(2);
			position: relative;

			&:after {
				content: '';
				position: absolute;
				top: pxToRem(2);
				left: pxToRem(2);
				right: pxToRem(2);
				bottom: pxToRem(2);
				background-color: $charcoal-grey-two;
				z-index: -1;
			}

			a {
				display: block;
				padding: pxToRem(9) pxToRem(14);
				color: $white;
				text-decoration: none;
				font-weight: bold;
				font-size: 1.063em; //17
				@include sq-transition(background-color 0.3s);
				background-color: transparent;

				&:hover,
				&:focus {
					background-color: rgba($black, 0.3);
				}
			}

			&.pink {
				&:after {
					background-color: $pink-active;
				}
			}

			&.orange {
				&:after {
					background-color: $orange-active;
				}
			}
        
	        &.olive {
				&:after {
					background-color: $olive-active;
				}

				a {
					color: $charcoal-grey-two;		
						
					&:hover,
					&:focus {
						background-color: rgba($white, 0.3);
					}			
				}
	        }
	        
	        &.azure {
				&:after {
					background-color: $azure-active;
				}
	        }
	        
	        &.teal {
				&:after {
					background-color: $teal-active;
				}

				a {
					color: $charcoal-grey-two;		
						
					&:hover,
					&:focus {
						background-color: rgba($white, 0.3);
					}			
				}
	        }
	        
	        &.purple {
				&:after {
					background-color: $purple-active;
				}
	        }
	        
	        &.khaki {
				&:after {
					background-color: $khaki-active;
				}
	        }
		}
	}
}
