/* Module: S */
.page-list {
	margin: 0;
	padding: pxToRem(10) 0 pxToRem(15) 0;

	&__item {
		text-decoration: none;
		padding: pxToRem(15) 0;

		a {
			display: block;
			@include sq-transition(background-color 0.3s);
			text-decoration: none;
			width: 100%;

			&:hover,
			&:focus {
				.page-list {
					&__image {
						&-wrapper {
							@include sq-transform(scale(1.1));	
						}
					}
				}
			}

		}
	}
	
	&__image {
		width: 100%;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		position: relative;

		&-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: center;
			@include sq-transform(scale(1));
			@include sq-transition(all 0.3s);
		}
	}

	&__info {
		padding-top: pxToRem(12);
	}

	&__title {
		font-size: 1.125em; //18
		font-weight: 900;
		line-height: 1.44;
		color: $charcoal-grey;
	}

	&__text {
		font-size: 1em; //16
		font-weight: 300;
		line-height: 1.5;
		color: $black-2;
		padding-top: pxToRem(6);
	}
}