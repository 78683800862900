/* Module: S */
.page-list {
    @include sq-flex-box;
    @include sq-flex-direction(row);	  
    @include sq-flex-wrap(wrap);	
    margin: 0 pxToRem(-15);
	padding: pxToRem(20) 0 pxToRem(25) 0;

	&__item {
		width: 50%;
		padding: pxToRem(12) pxToRem(15);
	    @include sq-flex-box;
	    @include sq-flex-direction(row);	

	    a {
			&:hover,
			&:focus {
				background-color: $silver;
			}
	    }
	}

    &__info {
    	padding: pxToRem(24) pxToRem(24) pxToRem(34) pxToRem(24);

    }

	&__text {
		padding-top: pxToRem(12);
	}
}