/* Module: S */
.banner-links {
	padding: pxToRem(34) 0;
	
	h2 {
		font-size: 1.750em; //28
		padding: pxToRem(18) pxToRem(22);
	}

	ul {
		padding: pxToRem(27) 0 0 0;
		margin: 0 pxToRem(-3);
		max-width: pxToRem(620);
		clear: both;

		li {
			padding: pxToRem(3) pxToRem(3);

			&:after {
				top: pxToRem(3);
				left: pxToRem(3);
				right: pxToRem(3);
				bottom: pxToRem(3);
			}

			a {
				padding: pxToRem(10) pxToRem(22);
				font-size: 1.375em; //22
			}
		}
	}
}