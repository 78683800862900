/* Module: S */
.related-links {
	margin: pxToRem(10) 0;
	padding: pxToRem(30) pxToRem(15) pxToRem(25) pxToRem(15);
	background-color: $white-2;

	.h4 {
		padding: 0;
	}

	ul {
		color: $black-2;
		margin: 0;
		padding: pxToRem(10) 0 0 pxToRem(17);

		li {
			padding: pxToRem(5) 0;
		}

		a {
			color: $black-2;
			text-decoration: none;
			font-size: 1em; //16
			font-weight: bold;
			line-height: 1.5;
			position: relative;

			&:focus,
			&:hover {
				text-decoration: underline;
			}
		}
	}

	p {
		margin: 0;
		padding: pxToRem(10) 0 0 0;
	}
}