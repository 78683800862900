/* Module: S */
.filter {
	padding: pxToRem(30) 0 pxToRem(20) 0;

	&__title {
		&.active {
			button {
				&:after {
					@include sq-transform(rotate(180deg));
				}
			}
		}

		button {
		    width: 100%;
		    padding: pxToRem(15) pxToRem(20);
		    text-align: center;
		    color: $white;
		    font-size: 1.125em; //18
			font-weight: 900;
			line-height: 1.44;
		    background-color: $charcoal-grey;
		    border: none;
		    border-radius: 0;
		    position: relative;
		    -webkit-appearance: none;
			@include sq-transition(background-color 0.3s);
			@include font-smoothing;

			&:hover,
			&:focus {
				background-color: rgba($charcoal-grey, 0.95);
			}

			&:after {
				content: '';
				background-image: url(../mysource_files/arrow-down.svg);
				background-repeat: no-repeat;
				background-size: pxToRem(20);
				background-position: center;
				position: absolute;
				top: pxToRem(0);
				right: pxToRem(20);
				width: pxToRem(20);
				height: pxToRem(55);
				@include sq-transition(all 0.3s);
			}
		}
	}

	&__content {
		margin-top: pxToRem(6);
		background-color: $charcoal-grey;
		padding: pxToRem(30) pxToRem(15);
		@include placeholder-color($black-2);
		@extend %clearfix;
		display: none;

		&.active {
			display: block;
		}

		label {
			font-size: 1.125em; //18
			font-weight: 900;
			color: $white;
			@include font-smoothing;
			padding-bottom: pxToRem(6);
			display: block;
		}
	}

	&__search {
		padding-bottom: pxToRem(24);

		&-wrapper {
			position: relative;
		}

		input[type="submit"] {
			border: none;
			border-radius: 0;
			background-color: $light-grey;
			background-image: url(../mysource_files/search.svg);
			background-position: center center;
			background-repeat: no-repeat;
			background-size: pxToRem(23);
			text-indent: -99999px;
			padding: pxToRem(13) pxToRem(15);
			@include clear-default-appearance;
			position: absolute;
			bottom: 0;
			top: 0;
			right: 0;
			padding: 0;
			width: pxToRem(50);

			@include sq-transition(background-color 0.3s);

			&:hover,
			&:focus {
				background-color: rgba($charcoal-grey-two, 0.15);
			}
		}
	}

	&__select {
		padding-bottom: pxToRem(24);
	}

	&__date {
		padding-bottom: pxToRem(24);

		.ui-datepicker {
			background-color: $white;
		}
	}

	&__inlinedate {
		padding-bottom: pxToRem(24);

		.form__field {
			width: 100%;
		}

		.ui-datepicker {
			background-color: $white;
			padding-bottom: pxToRem(22);

			&-calendar {
				width: 100%;
				min-width: pxToRem(216);
			}
		}
	}

	&__controls {
		text-align: right;
	}

	&__radiobuttons {
		@include font-smoothing;
		padding-bottom: pxToRem(19);

		&-wrapper {
			@extend %clearfix;
		}

		&-item {
			float: left;
			padding-right: pxToRem(5);
			padding-bottom: pxToRem(5);

			&:last-child {
				padding-right: 0;
			}
		}

		fieldset {
			input[type="radio"] {
				&:checked{
					& + label {
						background-color: $blueberry;
					}
				}

				& + label {
					display: block;
					background-color: rgba($battleship-grey, 0.3);
					color: $white;
					font-size: 0.875em; //14
					font-weight: 900;
					padding: pxToRem(7) pxToRem(14);
					@include sq-transition(background-color 0.3s);
					cursor: pointer;
					-webkit-tap-highlight-color: transparent;

					&:hover,
					&:focus {
						background-color: $blueberry;
					}

					&:before,
					&:after {
						display: none;
					}
				}
			}
		}

	}

	&-whatson {
		padding-bottom: 0;
	}

	&-search {
		.filter {
			&__content {
				padding: 0;
				margin-top: 0;
			}

			&__search {
				width: 100%;
				padding-bottom: 0;
				border: 1px solid $battleship-grey;
			}
		}
	}
}

.criteria-wrapper__inner {
	font-weight: bold;
}

.ui-datepicker-current-day {
	.ui-state-active {
		background-color: #5c348b !important;
		color: #fff !important;
	}
}