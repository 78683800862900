/* Module: S */
.responsive-table {
	width: 100%;
	border-collapse: collapse;
	border-top: none;

    th {
		position: static;
		top: auto;
		left: auto;

		&:last-child {
			border-right: none;
		}

		&.th-10 {
			width: 10%;
		}

		&.th-15 {
			width: 15%;
		}

		&.th-25 {
			width: 25%;
		}
    }

    tbody {
    	display: table-row-group;
    	margin-top: 0;
    	border-top: 1px solid $charcoal-grey-two;

	    tr {
	    	display: table-row;

			&:nth-child(even) {
				background-color: $white-two;
			}

	        &:before,
	        &:after {
	            display: none;
	        }
	    }	
    }

    thead {
    	border-top: 1px solid $charcoal-grey-two;
    	
    	th {
			border-right: 1px solid $white;
    	}
    }
    
    td {
    	display: table-cell;
    	width: auto;
		border-bottom: none;
		border-right: 1px solid $warm-grey;
		float: none;

		&:nth-child(even) {
			background-color: transparent;
		}

		&:last-child {
			border-right: none;
		}

        &:first-child {
            border-left: none;
        }

        &:before,
        &:after {
            display: none;
        }

        span {
    		width: 100%;
        }
    }

    &-plain {
    	tbody {
	        tr {
	            &:nth-child(even) {
	                background-color: transparent;
	            }
	        }
    	}
    }
}