/* Module: S */
.breadcrumbs {	
    background-image: url(../mysource_files/bg-lines.png);
    background-repeat: repeat;

    &__wrapper {    	
        padding: 0 pxToRem(10);
        max-width: pxToRem(1160); //1140 + 20
        margin: 0 auto; 
    }

	a {
		color: $black-2;
		text-decoration: none;
		display: inline-block;

		&:hover {
			text-decoration: underline;
		}
	}

	span {
		font-weight: 900;		
	}

	ul {
		padding: pxToRem(10) 0;
		margin: 0;
		list-style-type: none;
		@extend %clearfix;

		li {
			padding: 0 pxToRem(8) 0 0;
			margin: 0;
			display: inline;
			float: left;
			background-color: $white;
			font-size: 1em;
			color: $black-2;
			font-weight: 300;	

			&:after {
				content: "/";
				padding-left: pxToRem(8);
				display: inline-block;
			}

			&:last-child {
				padding-right: 0;

				&:after {
					display: none;
				}
			}
		}
	}
}