/* Module: S */
.info-title {
    font-size: 1.125em; //18
    font-weight: 900;
    line-height: 1.17;
    color: $charcoal-grey;
    padding: pxToRem(10) 0;
}

.info-block {
    padding: pxToRem(5) 0;

    &__title {
        background-color: $blueberry;
        color: $white;
        font-size: 1.125em; //18
        font-weight: 900;
        line-height: 1.44;
        padding: pxToRem(10) pxToRem(15);  
        @include font-smoothing;
    }

    &__content {
        background-color: $white-2;
        font-size: 0.875em; //14
        font-weight: 300;
        line-height: 1.57;
        padding: pxToRem(15);  

        p {
            margin: 0;
            padding: 0 0 pxToRem(15) 0;

            &:last-child {
                padding: 0;
            }

            strong {
                font-weight: 900;
            }

            a {
                color: $black-2;
                text-decoration: none;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }      
    }

    .map {
        padding: 0;
    }

    .button {
        display: block;
        background-color: $pinkish-grey;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        color: $charcoal-grey;
        font-size: 0.938rem; //15
        line-height: 3;
        font-weight: 900; 
        padding: 0 pxToRem(15);
        @include font-smoothing;
        @include sq-transition(background-color 0.3s);

        &:hover,
        &:focus {
            text-decoration: none;
            background-color: rgba($pinkish-grey, 0.7);
        }

        &.calendar {
            &:before {
                content: '';
                width: 28px;
                height: 22px;
                background: url(../mysource_files/calendar.svg) left top no-repeat;
                background-size: pxToRem(18);
                position: relative;
                top: pxToRem(5);
                display: inline-block;
            }
        }

    }
}